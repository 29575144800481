import React, {useEffect} from "react";
import {useGetIdentity, useTranslate} from "@pankod/refine-core";
import {
  Autocomplete,
  Box,
  DataGrid, Divider,
  Grid,
  GridColumns, GridToolbarContainer, GridToolbarDensitySelector,
  ruRU, TextField, useAutocomplete,
  useDataGrid
} from "@pankod/refine-mui";
import {IEmployee, IReportMonthly} from "../../interfaces";
import {List} from "../../components/crud/list";
import { IConsumerRouteParams } from "pages/consumers";
import routerProvider from "@pankod/refine-react-router-v6";
import {renderFullNameShort} from "../../utils/dataRenderUtils";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";

const { useParams, Link } = routerProvider;

export const MonthlyReportList = (): JSX.Element => {
  const t = useTranslate();
  const urlParams: IConsumerRouteParams = useParams();
  const { data: user } = useGetIdentity();

  const currentEmployee: IEmployee|null = user?.employees[urlParams.org_id];
  const [total, setTotal] = React.useState(0);
  const [filterEmployeeId, setFilterEmployeeId] = React.useState(currentEmployee?.id ?? 0);
  const [filterMonth, setFilterMonth] = React.useState(dayjs().format('YYYY-MM-01'));

  const {dataGridProps, setFilters} = useDataGrid<IReportMonthly>({
    resource: `organizations/${urlParams.org_id}/reports/monthly`,
    hasPagination: false,
    syncWithLocation: false,
    defaultSetFilterBehavior: "replace",
  });

  useEffect(() => {
    setFilterEmployeeId(currentEmployee?.id ?? filterEmployeeId);
  }, [currentEmployee]);

  useEffect(() => {
    setFilters([{
      field: "employee",
      operator: "eq",
      value: filterEmployeeId
    }, {
      field: "startDate",
      operator: "eq",
      value: filterMonth
    }]);
  }, [filterEmployeeId, filterMonth]);

  const columns = React.useMemo<GridColumns<IReportMonthly>>(
    () => [
      {
        field: "c_id",
        headerName: t("reports.cId"),
        width: 50,
        hideable: false,
        renderCell: function render ({row}) {
          return <Link to={`/organizations/${urlParams.org_id ?? 0}/consumers/show/${row.c_id}`}>{row.c_id ?? ""}</Link>
        }
      },
      {
        field: "c_phone",
        headerName: t("customers.phone"),
        flex: 1,
      },
      {
        field: "c_comment",
        headerName: t("customers.comment"),
        flex: 1,
      },
      {
        field: "c_created",
        headerName: t("entity.dateCreated"),
        flex: 1,
      },
      {
        field: "c_updated",
        headerName: t("entity.dateUpdated"),
        flex: 1,
      },
      {
        field: "a_id",
        headerName: t("reports.aId"),
        flex: 1,
      },
      {
        field: "a_date",
        headerName: t("reports.aDate"),
        flex: 1,
      },
      {
        field: "a_descr",
        headerName: t("reports.aDescr"),
        flex: 1,
      },
      {
        field: "a_comment",
        headerName: t("reports.aComment"),
        flex: 1,
      },
      {
        field: "d_comment",
        headerName: t("reports.dComment"),
        flex: 1,
      },
      {
        field: "s_title",
        headerName: t("reports.sTitle"),
        flex: 1,
      },
      {
        field: "dsi_id",
        headerName: t("reports.dsiId"),
        flex: 1,
      },
      {
        field: "dsi_exec_date",
        headerName: t("reports.dsiExecDate"),
        flex: 1,
      },
      {
        field: "dsi_comment",
        headerName: t("reports.dsiComment"),
        flex: 1,
      },
      {
        field: "total_price",
        headerName: t("dealServices.customTotalPrice"),
        flex: 1,
      },
    ],
    [t]
  );

  return (
    <List
      title={t("reports.monthlySalesTitle", {month: dayjs(filterMonth).format('MMMM')})}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        getRowHeight={() => 'auto'}
        getRowId={(row) => row.dsi_id}
        getEstimatedRowHeight={() => 45}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Toolbar: CustomGridToolbar,
          Footer: CustomFooterTotalComponent,
        }}
        componentsProps={{
          footer: { total },
          toolbar: { filterEmployeeId, setFilterEmployeeId, filterMonth, setFilterMonth },
        }}
        onStateChange={(state) => {
          let t: number = 0;
          if (!state.rows.loading) {
            dataGridProps.rows.forEach((v) => {
              t += parseFloat(v.total_price);
            })
          }
          setTotal(t);
        }}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
        }}
      />
    </List>
  );
}

const CustomFooterTotalComponent = ({total}: { total: number }): JSX.Element => {
  const t = useTranslate();
  return (
    <Box sx={{ padding: "10px", display: "flex" }}>{t('reports.totalSalesSum', {sum: total})}</Box>
  );
}

const CustomGridToolbar = ({filterEmployeeId, setFilterEmployeeId, filterMonth, setFilterMonth}: {
  filterEmployeeId: number,
  setFilterEmployeeId: (id: number) => void,
  filterMonth: string,
  setFilterMonth: (month: string) => void
}): JSX.Element => {
  const t = useTranslate();
  const urlParams: IConsumerRouteParams = useParams();
  const { autocompleteProps } = useAutocomplete<IEmployee>({
    resource: `organizations/${urlParams.org_id}/employees`,
    hasPagination: true,
    queryOptions: { enabled: true },
    defaultValueQueryOptions: { enabled: false },
    debounce: 700,
  });

  return (
    <GridToolbarContainer>
      <Grid container>
        <Grid item sm={3}>
          <Autocomplete
            {...autocompleteProps}
            autoSelect={true}
            disableClearable={true}
            noOptionsText={t('common.searchNoItems')}
            defaultValue={autocompleteProps.options.find((i) => i.id === filterEmployeeId)}
            size="small"
            onChange={(_, value) => {
              setFilterEmployeeId(value?.id);
            }}
            getOptionLabel={(item) => {
              return renderFullNameShort(item.lastName
                ? item
                : autocompleteProps?.options?.find(
                  (p) =>
                    p.id.toString() ===
                    item.toString(),
                )) ?? "";
            }}
            isOptionEqualToValue={(option, value: IEmployee|number) =>
              value === undefined || option.id.toString() === value.toString() || (typeof value === 'object' && option.id === value?.id)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("sideContextMenu.reportMonthly.employeeFilter")}
                margin="normal"
                variant="standard"
              />
            )}
          />
        </Grid>
        <Grid item sm={2}>
          <DatePicker
            views={['month', 'year']}
            label={t("sideContextMenu.reportMonthly.dateFilter")}
            value={filterMonth}
            onChange={value => setFilterMonth(dayjs(value).format('YYYY-MM-01'))}
            renderInput={(params: any) => <TextField {...params} margin="normal" size="small" variant="standard"/>}
          />
        </Grid>
        <Divider orientation="vertical" variant="middle" flexItem light/>
        <Grid item sm>
          <GridToolbarDensitySelector />
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}