import { useTranslate, HttpError } from "@pankod/refine-core";
import {
  Create,
  Box,
  TextField, FormControlLabel, Switch, Autocomplete,
} from "@pankod/refine-mui";
import {Controller, useForm} from "@pankod/refine-react-hook-form";

import { IEmployee } from "interfaces";
import React from "react";
import {Typography} from "@mui/material";

export const EmployeeCreate: React.FC = () => {
  const t = useTranslate();

  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    control,
    formState: { errors },
  } = useForm<IEmployee, HttpError, IEmployee>();

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps} title={<Typography variant="h5">{t("employees.titles.create")}</Typography>}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("email", {
            required: t("errors.required.field", { field: t("employees.email") }),
          })}
          error={!!errors.email}
          helperText={errors.email?.message}
          margin="normal"
          fullWidth
          label={t("employees.email")}
          name="email"
          autoFocus
        />
        <TextField
          {...register("firstName", {
            required: t("errors.required.field", { field: t("employees.firstName") }),
          })}
          error={!!errors.firstName}
          helperText={errors.firstName?.message}
          margin="normal"
          fullWidth
          label={t("employees.firstName")}
          name="firstName"
        />
        <TextField
          {...register("lastName", {
            required: t("errors.required.field", { field: t("employees.lastName") }),
          })}
          error={!!errors.lastName}
          helperText={errors.lastName?.message}
          margin="normal"
          fullWidth
          label={t("employees.lastName")}
          name="lastName"
        />
        <TextField
          {...register("middleName", {
            required: t("errors.required.field", { field: t("employees.middleName") }),
          })}
          error={!!errors.middleName}
          helperText={errors.middleName?.message}
          margin="normal"
          fullWidth
          label={t("employees.middleName")}
          name="middleName"
        />
        <FormControlLabel control={<Switch
          {...register("active")}
          name="active"
        />} label={t("employees.active")} />
        <Controller
          control={control}
          name="permissions"
          defaultValue={[]}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={[
                'perm_customer_assign_manager',
                'perm_organization_employee_manage',
                'perm_edit_action_in_status_done',
                'perm_voucher_add',
                'perm_voucher_edit',
                'perm_voucher_activation_add',
                'perm_voucher_activation_edit',
              ]}
              clearOnEscape
              disableCloseOnSelect
              multiple
              noOptionsText={t('common.searchNoItems')}
              onChange={(_, value) => {
                field.onChange(value.map((i) => i) || value);
              }}
              getOptionLabel={(option) => t(`employees.${option}`)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("employees.permissions")}
                  margin="normal"
                  variant="outlined"
                  error={!!errors.permissions}
                  //@ts-ignore
                  helperText={errors.permissions?.message}
                />
              )}
            />
          )}
        />

      </Box>
    </Create>
  );
};
