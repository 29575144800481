import {Controller, UseModalFormReturnType} from "@pankod/refine-react-hook-form";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery, useTheme} from "@pankod/refine-mui";
import {localToUTC, makeLocalAppearUTC} from "../../components/fields/dateTimePicker";
import dayjs from "dayjs";
import {DateTimePicker} from "@mui/x-date-pickers";
import React from "react";
import {useTranslate} from "@pankod/refine-core";

export const ActionCreateModal: React.FC<UseModalFormReturnType> = ({
  register,
  control,
  formState: { errors },
  refineCore: { formLoading },
  // handleSubmit,
  modal: { visible, close },
  saveButtonProps,
}) => {
  const t = useTranslate();
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open={visible}
      onClose={close}
      maxWidth="xs"
      // fullScreen={fullScreen}
    >
      <DialogTitle>{t("customers.actions.titles.create")}</DialogTitle>
      <DialogContent>
        <TextField
          {...register("description", {
            required: t("errors.required.field", { field: t("customers.actions.description") }),
          })}
          error={!!errors.description}
          // @ts-ignore
          helperText={errors.description?.message}
          margin="normal"
          fullWidth
          label={t("customers.actions.description")}
          required
          name="description"
          autoFocus
        />

        <TextField
          {...register("comment")}
          margin="normal"
          fullWidth
          label={t("customers.actions.comment")}
          multiline={true}
          maxRows={5}
          name="comment"
        />

        <Controller
          control={control}
          name="dateTime"
          rules={{
            required: t("errors.required.field", { field: t("customers.actions.dateTime") }),
          }}
          // @ts-ignore
          defaultValue={localToUTC(dayjs((new Date()).valueOf()))}
          render={({ field }) => (
            <DateTimePicker
              {...field}
              label={t("customers.actions.dateTime")}
              value={makeLocalAppearUTC(field.value)}
              onChange={value => field.onChange({ target: { value: localToUTC(value) } })}
              renderInput={(params: any) => <TextField
                {...params}
                error={!!errors.dateTime}
                helperText={errors.dateTime?.message}
                required
                margin="normal"
              />}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close()}>{t("buttons.cancel")}</Button>
        <Button {...saveButtonProps}>{t("common.save")}</Button>
      </DialogActions>
    </Dialog>
  );
};