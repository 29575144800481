import {ICustomerAction} from "../../interfaces";
import {useInvalidate, usePermissions, useTranslate, useUpdate} from "@pankod/refine-core";
import {Clear, Done, PriorityHigh} from "@mui/icons-material";
import {blue, green, grey, red} from "@mui/material/colors";
import {
  Avatar,
  AvatarGroup,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Tooltip,
  Typography
} from "@pankod/refine-mui";
import {DateField} from "../../components/fields/date";
import {renderFullNameLong, renderInitials} from "../../utils/dataRenderUtils";
import React from "react";
import routerProvider from "@pankod/refine-react-router-v6";
import { IConsumerRouteParams } from "pages/consumers";
import {useModalForm} from "@pankod/refine-react-hook-form";
import { ActionEditModal } from "./editModal";
import { PERM_EDIT_ACTION_IN_STATUS_DONE } from "constants/permissions";

interface CustomerActionCardProps {
  action: ICustomerAction;
}

const { useParams } = routerProvider;

export default function CustomerActionCard(props: CustomerActionCardProps) {
  let { action } = props;
  const t = useTranslate();
  const params: IConsumerRouteParams = useParams();

  const invalidate = useInvalidate();
  const { mutate } = useUpdate<ICustomerAction>();
  const { data: permissionsData } = usePermissions();
  let orgPermList = [];
  if (permissionsData && permissionsData[parseInt(params.org_id)]) {
    orgPermList = permissionsData[parseInt(params.org_id)];
  }

  const editModalFormReturnValues = useModalForm({
    refineCoreProps: {
      resource: `organizations/${params.org_id}/consumers/${params.id}/actions`,
      action: "edit",
      redirect: false,
      onMutationSuccess: () => {
        invalidate({
          resource: `organizations/${params.org_id}/consumers`,
          invalidates: ["detail"],
          id: params.id,
        });
      },
    },
  });

  const {
    modal: { show: showEditModal },
  } = editModalFormReturnValues;

  function setStatus(status: number) {
    mutate({
      resource: `organizations/${params.org_id}/consumers/${params.id}/actions`,
      id: action.id,
      values: { status: status },
    }, {
      onSuccess: (data) => {
        action = data.data;
        invalidate({
          resource: `organizations/${params.org_id}/consumers`,
          invalidates: ["detail"],
          id: params.id,
        });
      }
    });
  }

  let icon = <></>;
  let iconBg;

  let actionDate = new Date(action.dateTime);
  let currentDate = new Date();
  if (action.status === 0 && actionDate.getTime() < currentDate.getTime()) {
    icon = <PriorityHigh
      fontSize="large"
      // sx={{ fontSize: 46 }}
      // color="error"
    />;
    iconBg = red[500];
  } else if (action.status === 1) {
    icon = <Done
      fontSize="large"
      // sx={{ fontSize: 46 }}
      // color="success"
    />;
    iconBg = green[500];
  } else if (action.status === 2) {
    icon = <Clear
      fontSize="large"
      // sx={{ fontSize: 46 }}
      // color="disabled"
    />;
    iconBg = grey[500];
  } else if (action.status === 0) {
    icon = <PriorityHigh
      fontSize="large"
      // sx={{ fontSize: 46 }}
      // color="primary"
    />;
    iconBg = blue[500];
  }

  return (
    <Card sx={{ minWidth: 300, maxWidth: 450 }}>
      <ActionEditModal {...editModalFormReturnValues} />
      <CardHeader
        title={action.description}
        avatar={<Tooltip title={t("customers.actions.status_" + action.status)}>
          <Avatar sx={{ bgcolor: iconBg }}>
            {icon}
          </Avatar>
        </Tooltip>}
        subheader={<DateField value={action.dateTime} format="LLL"/>}
        action={<AvatarGroup>
          {action.creator && <Tooltip title={`${t("customers.actions.creator")}: ${renderFullNameLong(action.creator)}`}>
              <Avatar sx={{ width: 24, height: 24, fontSize: 12 }}>{renderInitials(action.creator)}</Avatar>
          </Tooltip>}
          {action.executor && <Tooltip title={`${t("customers.actions.executor")}: ${renderFullNameLong(action.executor)}`}>
              <Avatar sx={{ width: 24, height: 24, fontSize: 12 }}>{renderInitials(action.executor)}</Avatar>
          </Tooltip>}
        </AvatarGroup>}
      />
      <CardContent>
        <Typography variant="body2">{action.comment}</Typography>
      </CardContent>
      <CardActions>
        {action.status === 0 && <Button size="small" onClick={() => {setStatus(1)}}>{t("customers.actions.actionDone")}</Button>}
        {action.status === 0 && <Button size="small" onClick={() => {setStatus(2)}}>{t("customers.actions.actionCancel")}</Button>}
        {(orgPermList?.includes(PERM_EDIT_ACTION_IN_STATUS_DONE) || action.status === 0) && <Button size="small" onClick={() => {showEditModal(action.id)}}>{t("common.edit")}</Button>}
      </CardActions>
    </Card>
  );
}