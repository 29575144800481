import React, {useMemo, useRef} from "react";

import {Card, CardContent, CardHeader, Checkbox, FormControlLabel, Typography} from "@pankod/refine-mui";
import {IReportCutomersStatistics} from "./index";
import {pieArcLabelClasses, PieChart} from '@mui/x-charts/PieChart';
import {useGetLocale, useTranslate} from "@pankod/refine-core";

interface CustomersStatisticsCardProps {
  title: string;
  data: Array<IReportCutomersStatistics>
}

export const CustomersStatisticsChart: React.FC<CustomersStatisticsCardProps> = ({title, data}) => {
  const locale = useGetLocale();
  const t = useTranslate();
  let total = useRef<number>(0);

  const [isLegendHidden, setIsLegendHidden] = React.useState(false);

  const labelClip = (label: string) => {
    if (label.length > 25) {
      return label.substring(0, 25) + '...';
    }
    return label;
  }

  const getArcLabel = (value: number): string => {
    const percent = value / total.current;
    return percent > 0.04 ? `${(percent * 100).toFixed(0)}%` : '';
  };

  const formatValue = (value: number): string => {
    const percent = value / total.current;
    return String(value) + ` (${(percent * 100).toFixed(0)}%)`;
  };

  const operationsData = useMemo(() => {
    total.current = 0;
    if (!data?.length) {
      return [];
    }

    return data
      .map((group) => {
        total.current = (total.current ?? 0.0) + Number(group.count);
        return ({
          label: (group.category || group.source || null) ?? t('common.undefined'),
          value: Number(group.count),
        });
      })
      .filter(
        (stage) =>
          stage.value !== null && stage.value !== undefined && stage.value > 0,
      )
      .sort((a, b) => (b.value ?? 0) - (a.value ?? 0));
  }, [data]);

  return (
    <Card>
      <CardHeader
        title={<div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography style={{ marginLeft: ".5rem" }}>{title + ' ' + total.current}</Typography>
          <FormControlLabel
            checked={isLegendHidden}
            control={
              <Checkbox onChange={(event) => setIsLegendHidden(event.target.checked)} />
            }
            label={t('common.hideLegend')}
            labelPlacement="end"
          />
        </div>} />
      <CardContent sx={{padding: 1}}>
        <PieChart
          series={[
            {
              paddingAngle: 0.5,
              innerRadius: 70,
              outerRadius: 115,
              cx: 125,
              // cy: 100,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 50, additionalRadius: -20, color: 'gray' },
              data: operationsData,
              arcLabel: (datum) => getArcLabel(datum.value),
              valueFormatter: (datum) => formatValue(datum.value),
            },
          ]}
          slotProps={{
            legend: {
              hidden: isLegendHidden,
              labelStyle: {
                fontSize: 12,
                width: 200,
              },
            },
          }}
          height={400}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'white',
              fontSize: 14,
            },
          }}
        >
          {/*<PieCenterLabel>{ renderCurrency(total, locale(), ) }</PieCenterLabel>*/}
        </PieChart>
      </CardContent>
    </Card>
  );
};