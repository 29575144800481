import { useTranslate, HttpError } from "@pankod/refine-core";
import {
  Create,
  Box,
  TextField,
} from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";

import { IOrganization } from "interfaces";
import React from "react";

export const OrganizationCreate: React.FC = () => {
  const t = useTranslate();

  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    formState: { errors },
  } = useForm<IOrganization, HttpError, IOrganization>();

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("shortName", {
            required: t("errors.required.field", { field: t("organizations.shortName") }),
          })}
          error={!!errors.shortName}
          helperText={errors.shortName?.message}
          margin="normal"
          fullWidth
          label={t("organizations.shortName")}
          name="shortName"
          autoFocus
        />
        <TextField
          {...register("fullName", {
            required: t("errors.required.field", { field: t("organizations.fullName") }),
          })}
          error={!!errors.fullName}
          helperText={errors.fullName?.message}
          margin="normal"
          fullWidth
          label={t("organizations.fullName")}
          name="fullName"
        />
      </Box>
    </Create>
  );
};
