import {UseModalFormReturnType} from "@pankod/refine-react-hook-form";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery, useTheme} from "@pankod/refine-mui";
import React from "react";
import {useTranslate} from "@pankod/refine-core";

export const DealCreateModal: React.FC<UseModalFormReturnType> = ({
  register,
  formState: { errors },
  refineCore: { formLoading },
  // handleSubmit,
  modal: { visible, close },
  saveButtonProps,
}) => {
  const t = useTranslate();
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open={visible}
      onClose={close}
      maxWidth="xs"
      // fullScreen={fullScreen}
    >
      <DialogTitle>{t("deals.titles.create")}</DialogTitle>
      <DialogContent>
        <TextField
          {...register("comment")}
          margin="normal"
          fullWidth
          label={t("deals.comment")}
          multiline={true}
          maxRows={5}
          name="comment"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close()}>{t("buttons.cancel")}</Button>
        <Button {...saveButtonProps}>{t("common.save")}</Button>
      </DialogActions>
    </Dialog>
  );
};