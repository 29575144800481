import {IConsumer} from "../../interfaces";
import {useInvalidate, useTranslate} from "@pankod/refine-core";
import React from "react";
import {IConsumerRouteParams} from "./index";
import {useModalForm} from "@pankod/refine-react-hook-form";
import {Box, Fab, Grid, Tab, Tabs, Tooltip} from "@pankod/refine-mui";
import {a11yProps, TabPanel} from "../../components/layout/tabs";
import {Add} from "@mui/icons-material";
import {ActionCreateModal} from "../customerActions";
import CustomerActionCard from "../customerActions/actionCard";
import routerProvider from "@pankod/refine-react-router-v6";
import { DealCard, DealCreateModal } from "pages/deals";

const { useParams } = routerProvider;

interface BasicTabsProps {
  consumer?: IConsumer;
}

export const ConsumerShowTabs: React.FC<BasicTabsProps> = ({consumer}) => {
  const t = useTranslate();
  const [tab, setTab] = React.useState(0);
  const params: IConsumerRouteParams = useParams();
  const invalidate = useInvalidate();

  const createActionModalFormReturnValues = useModalForm({
    refineCoreProps: {
      resource: `organizations/${params.org_id}/consumers/${params.id}/actions`,
      action: "create",
      redirect: false,
      onMutationSuccess: () => {
        invalidate({
          resource: `organizations/${params.org_id}/consumers`,
          invalidates: ["detail"],
          id: params.id,
        });
      },
    },
  });

  const createDealModalFormReturnValues = useModalForm({
    refineCoreProps: {
      resource: `organizations/${params.org_id}/consumers/${params.id}/deals`,
      action: "create",
      redirect: false,
      onMutationSuccess: () => {
        invalidate({
          resource: `organizations/${params.org_id}/consumers`,
          invalidates: ["detail"],
          id: params.id,
        });
      },
    },
  });

  if (!consumer) {
    return <></>;
  }

  const handleChangeTab = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const {
    modal: { show: showCreateActionModal },
  } = createActionModalFormReturnValues;

  const {
    modal: { show: showCreateDealModal },
  } = createDealModalFormReturnValues;

  let actions = [...consumer.actions];
  actions.sort((a, b) => {
    if (a.status === b.status) {
      return (new Date(a.dateTime)).valueOf() - (new Date(b.dateTime)).valueOf();
    }
    return a.status - b.status;
  });

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab label={t("customers.actionsTab")} {...a11yProps(0)} />
          <Tab label={t("consumers.dealsTab")} {...a11yProps(1)} />
          <Tab disabled label={t("consumers.invoicesTab")} {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <Tooltip title={t("customers.actions.addNew")}>
          <Fab color="success" aria-label="add" size="large" onClick={() => showCreateActionModal()} sx={{ position: 'fixed', bottom: 16, right: 16 }}>
            <Add />
          </Fab>
        </Tooltip>
        <ActionCreateModal {...createActionModalFormReturnValues} />
        <Grid container direction="row" columns={3} spacing={2}>
          {actions.map(value => {
            return <Grid key={value.id} item><CustomerActionCard action={value} /></Grid>
          })}
        </Grid>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        {/*<SpeedDialTooltipOpen actions={[*/}
        {/*  {icon: <Add />, name: t("deals.addNew"), onClick: () => {showCreateDealModal()}},*/}
        {/*  {icon: <Add />, name: t("deals.addItem"), onClick: () => {showCreateDealModal()}}*/}
        {/*]} />*/}
        <Tooltip title={t("deals.addNew")}>
          <Fab color="success" aria-label="add" size="large" onClick={() => showCreateDealModal()} sx={{ position: 'fixed', bottom: 16, right: 16 }}>
            <Add />
          </Fab>
        </Tooltip>
        <DealCreateModal {...createDealModalFormReturnValues} />
        <Grid container direction="row" columns={3} spacing={2}>
          {consumer.deals.map(value => {
            return <Grid key={value.id} item><DealCard deal={value} /></Grid>
          })}
        </Grid>
      </TabPanel>
      <TabPanel value={tab} index={2}>
        Under development {/* @TODO implement*/}
      </TabPanel>
    </Box>
  );
}