import {Controller, UseModalFormReturnType, useWatch} from "@pankod/refine-react-hook-form";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  MenuItem,
  TextField,
  useAutocomplete,
} from "@pankod/refine-mui";
import React from "react";
import {useGetLocale, usePermissions, useTranslate} from "@pankod/refine-core";
import {IConsumerRouteParams} from "../consumers";
import routerProvider from "@pankod/refine-react-router-v6";
import {PERM_EDIT_DEAL_IN_FINAL_STATUS} from "constants/permissions";
import {
  DEAL_STATUS_CANCELED,
  DEAL_STATUS_CLOSED,
  DEAL_STATUS_DRAFT,
  DEAL_STATUS_PAYMENT_PENDING,
  DEAL_STATUS_SERVING
} from "../../constants/deal";
import {ICustomerAction} from "../../interfaces";
import {renderDateTime, renderFullNameShort} from "../../utils/dataRenderUtils";
import {CUSTOMER_ACTION_STATUS_DONE} from "../../constants/customerAction";

const { useParams } = routerProvider;

export const DealEditModal: React.FC<UseModalFormReturnType> = ({
  register,
  control,
  formState: { errors },
  refineCore: { formLoading, queryResult },
  // handleSubmit,
  modal: { visible, close },
  saveButtonProps,
}) => {
  const t = useTranslate();
  const locale = useGetLocale();
  const params: IConsumerRouteParams = useParams();
  const { data: permissionsData } = usePermissions();
  const { autocompleteProps: actionsAutocompleteProps } = useAutocomplete<ICustomerAction>({
    resource: `organizations/${params.org_id}/consumers/${queryResult?.data?.data.consumer}/actions`,
    hasPagination: true,
    queryOptions: { enabled: visible && !!queryResult?.data?.data },
    defaultValueQueryOptions: { enabled: true },
    filters: [{
      field: 'status',
      operator: 'eq',
      value: CUSTOMER_ACTION_STATUS_DONE,
    }, {
      // @TODO correct this filter, current linked action should be selectable
      field: 'deal',
      operator: 'eq',
      value: null,
    }],
    debounce: 700,
  });
  let orgPermList = [];
  if (permissionsData && permissionsData[parseInt(params.org_id)]) {
    orgPermList = permissionsData[parseInt(params.org_id)];
  }

  const dealStatus: string = useWatch({
    control,
    name: "status",
    defaultValue: queryResult?.data?.data.status
  });

  return (
    <Dialog
      open={visible}
      onClose={close}
      maxWidth="xs"
      // fullScreen={fullScreen}
    >
      {formLoading && <LinearProgress />}
      <DialogTitle>{t("deals.titles.edit")}</DialogTitle>
      <DialogContent>
        <TextField
          {...register("comment")}
          margin="normal"
          fullWidth
          label={t("deals.comment")}
          multiline={true}
          maxRows={5}
          name="comment"
        />

        {(queryResult?.data?.data.status !== DEAL_STATUS_CLOSED || orgPermList?.includes(PERM_EDIT_DEAL_IN_FINAL_STATUS)) &&
          <Controller
            control={control}
            name="status"
            rules={{
              required: t("errors.required.field", {field: t("deals.status")}),
            }}
            defaultValue={DEAL_STATUS_DRAFT}
            render={({field}) => (
              <TextField
                {...field}
                margin="normal"
                fullWidth
                label={t("deals.status")}
                select={true}
                required
                error={!!errors.status}
                // @ts-ignore
                helperText={errors.status?.message}
              >
                <MenuItem value={DEAL_STATUS_DRAFT}>{t('deals.status_' + DEAL_STATUS_DRAFT)}</MenuItem>
                <MenuItem value={DEAL_STATUS_PAYMENT_PENDING}>{t('deals.status_' + DEAL_STATUS_PAYMENT_PENDING)}</MenuItem>
                <MenuItem value={DEAL_STATUS_SERVING}>{t('deals.status_' + DEAL_STATUS_SERVING)}</MenuItem>
                <MenuItem value={DEAL_STATUS_CLOSED}>{t('deals.status_' + DEAL_STATUS_CLOSED)}</MenuItem>
                <MenuItem value={DEAL_STATUS_CANCELED}>{t('deals.status_' + DEAL_STATUS_CANCELED)}</MenuItem>
              </TextField>
            )}
          />
        }

        {[DEAL_STATUS_CLOSED, DEAL_STATUS_SERVING].includes(dealStatus) &&
          <Controller
            control={control}
            // rules={{
            //   required: t("errors.required.field", { field: t("deal.action") }),
            // }}
            name="action"
            defaultValue={null}
            render={({ field }) => (
              <Autocomplete
                {...actionsAutocompleteProps}
                {...field}
                clearOnEscape
                noOptionsText={t('common.searchNoItems')}
                onChange={(_, value) => {
                  field.onChange(value?.id || value);
                }}
                getOptionLabel={(item) => {
                  const data = item.description ? item : actionsAutocompleteProps?.options?.find(
                    (p) => p.id.toString() === item.toString(),
                  );
                  return data?.description ? `${data.description} (${renderDateTime(data.dateTime, locale(), "DD.MM.YY HH:mm")}) вып.: ${renderFullNameShort(data.executor)}` : "";
                }}
                isOptionEqualToValue={(option, value: ICustomerAction|number) => {
                  return value === undefined || option.id.toString() === value.toString() || (typeof value === 'object' && option.id === value?.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("deals.action")}
                    margin="normal"
                    variant="outlined"
                    // required
                    error={!!errors.action}
                    //@ts-ignore
                    helperText={errors.action?.message}
                  />
                )}
              />
            )}
          />
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close()}>{t("buttons.cancel")}</Button>
        <Button {...saveButtonProps}>{t("common.save")}</Button>
      </DialogActions>
    </Dialog>
  );
};