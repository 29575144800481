import RouterProvider from "@pankod/refine-react-router-v6";
import {useLocation, useParams} from "react-router-dom";
import {handleUseParams} from "@pankod/refine-core";

export const routerProvider = {
  ...RouterProvider,

  useParams: () => {
    const params = useParams();
    const { pathname } = useLocation();
    let resource = decodeURIComponent(pathname.substring(1));
    if (Object.keys(params).length !== 0) {
      if (params.id) {
        resource = resource.replace(RegExp(`/${params.id}$`), "");
      }
      if (params.action) {
        resource = resource.replace(RegExp(`/${params.action}$`), "");
      }
      // Object.values(params).forEach((param) => {
      //   resource = resource.replace(`/${param}`, "");
      // });
    }
    return handleUseParams({
      ...params,
      resource: resource
    });
  },
}
