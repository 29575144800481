import { useTranslate, HttpError } from "@pankod/refine-core";
import {
  Box,
  TextField,
} from "@pankod/refine-mui";
import { useForm} from "@pankod/refine-react-hook-form";

import {IServiceType} from "interfaces";
import React from "react";
import {Typography} from "@mui/material";
import {createErrorNotification} from "../../utils/errorNotification";
import routerProvider from "@pankod/refine-react-router-v6";
import {IServiceTypeRouteParams} from "./index";
import {Edit} from "../../components/crud/edit";

const { useParams } = routerProvider;

export const ServiceTypeEdit: React.FC = () => {
  const t = useTranslate();
  const params: IServiceTypeRouteParams = useParams();

  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    formState: { errors },
  } = useForm<IServiceType, HttpError, IServiceType>({
    refineCoreProps: {
      resource: `organizations/${params.org_id}/services`,
      ...createErrorNotification(t, "organizationServices.titles.edit"),
    },
  });

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title={<Typography variant="h5">{t("organizationServices.titles.edit")}</Typography>}
      canDelete={true}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("title", {
            required: t("errors.required.field", { field: t("organizationServices.title") }),
          })}
          error={!!errors.title}
          helperText={errors.title?.message}
          margin="normal"
          fullWidth
          label={t("organizationServices.title")}
          name="title"
          autoFocus
        />
        <TextField
          {...register("description")}
          error={!!errors.description}
          helperText={errors.description?.message}
          margin="normal"
          fullWidth
          label={t("organizationServices.description")}
          name="description"
        />
        <TextField
          {...register("duration")}
          error={!!errors.duration}
          helperText={errors.duration?.message || t("organizationServices.durationHelperText")}
          margin="normal"
          fullWidth
          label={t("organizationServices.duration")}
          name="duration"
        />
        <TextField
          {...register("cost", {
            required: t("errors.required.field", { field: t("organizationServices.cost") }),
          })}
          error={!!errors.cost}
          helperText={errors.cost?.message}
          margin="normal"
          fullWidth
          label={t("organizationServices.cost")}
          name="cost"
        />
        <TextField
          {...register("currency", {
            required: t("errors.required.field", { field: t("organizationServices.currency") }),
          })}
          error={!!errors.currency}
          helperText={errors.currency?.message}
          margin="normal"
          fullWidth
          label={t("organizationServices.currency")}
          name="currency"
        />

      </Box>
    </Edit>
  );
};
