/** Allow to assign and reassign a manager on a customer */
export const PERM_CUSTOMER_ASSIGN_MANAGER = "perm_customer_assign_manager";
/** Allow to manage organization employees (create, edit, delete) */
export const PERM_ORGANIZATION_EMPLOYEE_MANAGE = "perm_organization_employee_manage";
/** Allow to edit actions which status is set to done */
export const PERM_EDIT_ACTION_IN_STATUS_DONE = "perm_edit_action_in_status_done";
/** Allow to edit deals which status is set to final */
export const PERM_EDIT_DEAL_IN_FINAL_STATUS = 'perm_edit_deal_in_final_status';
/** Allow to issue new vouchers */
export const PERM_VOUCHER_ADD = "perm_voucher_add";
/** Allow to issue new vouchers */
export const PERM_VOUCHER_EDIT = "perm_voucher_edit";
/** Allow to activate vouchers */
export const PERM_VOUCHER_ACTIVATION_ADD = "perm_voucher_activation_add";
/** Allow to edit vouchers activations */
export const PERM_VOUCHER_ACTIVATION_EDIT = "perm_voucher_activation_edit";
/** Allow to view accounts */
export const PERM_FINANCE_ACCOUNTS = 'perm_finance_accounts';
/** Allow to add accounts */
export const PERM_FINANCE_ACCOUNT_ADD = 'perm_finance_account_add';
/** Allow to edit accounts */
export const PERM_FINANCE_ACCOUNT_EDIT = 'perm_finance_account_edit';
/** Allow to add operations */
export const PERM_FINANCE_OPERATION_ADD = 'perm_finance_operation_add';
/** Allow to edit operations */
export const PERM_FINANCE_OPERATION_EDIT = 'perm_finance_operation_edit';