import {IServiceType} from "../../interfaces";
import {useShow, useTranslate} from "@pankod/refine-core";
import {
  Stack,
  Typography
} from "@pankod/refine-mui";
import { Show } from "components/crud/show";
import React from "react";

export const ServiceTypeShow: React.FC = () => {
  const { queryResult } = useShow<IServiceType>();
  const t = useTranslate();

  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}
          title={<Typography variant="h5">{t("organizationServices.titles.show")}</Typography>} // @TODO this and next props should not be used, this data comes form resource
    >
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">{t("organizationServices.title")}</Typography>
        <Typography variant="body2">{record?.title}</Typography>
        <Typography variant="body1" fontWeight="bold">{t("organizationServices.description")}</Typography>
        <Typography variant="body2">{record?.description}</Typography>
        <Typography variant="body1" fontWeight="bold">{t("organizationServices.duration")}</Typography>
        <Typography variant="body2">{record?.duration ? record?.duration / 60 : ''} {t("common.time.minShort")}</Typography>
        <Typography variant="body1" fontWeight="bold">{t("organizationServices.cost")}</Typography>
        <Typography variant="body2">{record?.cost} {record?.currency}</Typography>
      </Stack>
    </Show>
  );
};