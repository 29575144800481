import {IFinanceAccount, IFinanceOperation} from "../../interfaces";
import {useGetLocale, useShow, useTranslate} from "@pankod/refine-core";
import {
  Chip,
  Stack,
  Typography
} from "@pankod/refine-mui";
import { Show } from "components/crud/show";
import {renderCurrency, renderFullNameLong} from "../../utils/dataRenderUtils";
import React from "react";
import {DateField} from "../../components/fields/date";
import {useParams} from "react-router-dom";

export const FinanceOperationShow: React.FC = () => {
  const { id } = useParams();
  const locale = useGetLocale();
  const { queryResult } = useShow<IFinanceOperation>({
    resource: 'operations',
    id: id,
  });
  const t = useTranslate();

  const { data, isLoading } = queryResult;
  const record = data?.data;

  function isFinanceAccount(arg: any): arg is IFinanceAccount {
    return arg
      && arg.name && typeof(arg.name) == 'string'
      && arg.currency && typeof(arg.currency) == 'string';
  }

  return (
    <Show isLoading={isLoading}
          title={<Typography variant="h5">{t("financeOperations.titles.show")}</Typography>} // @TODO this and next props should not be used, this data comes form resource
    >
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">{t("financeOperations.account")}</Typography>
        <Typography variant="body2">{isFinanceAccount(record?.account) ? record?.account.name : ''}</Typography>
        <Typography variant="body1" fontWeight="bold">{t("financeOperations.type")}</Typography>
        <Typography variant="body2">{t("financeOperations.type_" + record?.type)}</Typography>
        <Typography variant="body1" fontWeight="bold">{t("financeOperations.amount")}</Typography>
        <Typography variant="body2">
          <Chip
            variant="outlined"
            color={record?.type !== 'Income' ? "error" : "success"}
            label={record?.amount
              ? renderCurrency(record.amount, locale(), isFinanceAccount(record?.account) ? record?.account?.currency : null, record?.type)
              : ''
            }
          />
        </Typography>
        <Typography variant="body1" fontWeight="bold">{t("financeOperations.date")}</Typography>
        <DateField value={record?.date} format="LLL"/>
        <Typography variant="body1" fontWeight="bold">{t("financeOperations.description")}</Typography>
        <Typography variant="body2">{record?.description}</Typography>
        <Typography variant="body1" fontWeight="bold">{t("financeOperations.category")}</Typography>
        <Typography variant="body2">{record?.category}</Typography>
        <Typography variant="body1" fontWeight="bold">{t("financeOperations.payee")}</Typography>
        <Typography variant="body2">{record?.payee}</Typography>
        <Typography variant="body1" fontWeight="bold">{t("financeOperations.createdBy")}</Typography>
        <Typography variant="body2">{record ? renderFullNameLong(record.createdBy) : ''}</Typography>
      </Stack>
    </Show>
  );
};