import {IFinanceAccount} from "../../interfaces";
import {HttpError, useApiUrl, useGetLocale, useNotification, useShow, useTranslate} from "@pankod/refine-core";
import {useForm} from "@pankod/refine-react-hook-form";
import {
  Input, LoadingButton,
  Stack,
  Typography
} from "@pankod/refine-mui";
import { Show } from "components/crud/show";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {renderCurrency} from "../../utils/dataRenderUtils";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";

export interface IPost {
  importCsv: Record<string, any>;
}

export const FinanceAccountShow: React.FC = () => {
  const params = useParams();
  const locale = useGetLocale();
  const { open, close } = useNotification();
  const [isUploadLoading, setIsUploadLoading] = useState(false);

  const { queryResult } = useShow<IFinanceAccount>({
    resource: 'accounts',
    id: params.id,
  });
  const t = useTranslate();

  const { data, isLoading } = queryResult;
  const record = data?.data;

  const apiUrl = useApiUrl();
  const {
    register,
    formState: { errors },
    setValue,
    setError,
    watch,
  } = useForm<IPost, HttpError, IPost>();

  const fileInput = watch("importCsv");

  const onChangeHandler = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    try {
      setIsUploadLoading(true);

      const formData = new FormData();

      const target = event.target;
      const file: File = (target.files as FileList)[0];

      formData.append("importCsv", file);

      const res = await axios.post<{ url: string }>(
        `${apiUrl}/organizations/${params.org_id}/accounts/${params.id}/import`,
        formData,
      );

      const { name, size, type, lastModified } = file;

      const filePayload = [
        {
          name,
          size,
          type,
          lastModified,
        },
      ];

      setValue("importCsv", filePayload, { shouldValidate: true });

      open?.({
        type: "success",
        message: t("common.success"),
        description: t("financeAccounts.import.fileUploaded"),
      });

      setIsUploadLoading(false);
    } catch (error) {
      open?.({
        type: "error",
        message: t("common.error"),
        // @ts-ignore
        description: error?.response?.data?.detail || error?.message,
      });
      setError("importCsv", { message: t("common.error") });
      setIsUploadLoading(false);
    }
  };

  return (
    <Show isLoading={isLoading}
          title={<Typography variant="h5">{t("financeAccounts.titles.show")}</Typography>} // @TODO this and next props should not be used, this data comes form resource
    >
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">{t("financeAccounts.name")}</Typography>
        <Typography variant="body2">{record?.name}</Typography>
        <Typography variant="body1" fontWeight="bold">{t("financeAccounts.currency")}</Typography>
        <Typography variant="body2">{record?.currency}</Typography>
        <Typography variant="body1" fontWeight="bold">{t("financeAccounts.initialBalance")}</Typography>
        <Typography variant="body2">{record?.initialBalance ? renderCurrency(record.initialBalance, locale(), record?.currency) : ''}</Typography>
        <Typography variant="body1" fontWeight="bold">{t("financeAccounts.overallBalance")}</Typography>
        <Typography variant="body2">{record?.overallBalance ? renderCurrency(record.overallBalance, locale(), record?.currency) : ''}</Typography>
        <Typography variant="body1" fontWeight="bold">{t("financeAccounts.number")}</Typography>
        <Typography variant="body2">{record?.number}</Typography>
      </Stack>
      <br/>
      <Typography variant="body1" fontWeight="bold">{t("financeAccounts.import.title")}</Typography>
      <Stack
        direction="row"
        gap={4}
        flexWrap="wrap"
        sx={{ marginTop: "16px" }}
      >
        <label htmlFor="importCsv-input">
          <Input
            id="importCsv-input"
            type="file"
            sx={{ display: "none" }}
            onChange={onChangeHandler}
          />
          <input
            id="file"
            {...register("importCsv", {
              required: t("errors.required.field", { field: t("financeAccounts.import.file") }),
            })}
            type="hidden"
          />
          <LoadingButton
            loading={isUploadLoading}
            loadingPosition="end"
            endIcon={<FileUploadIcon />}
            variant="contained"
            component="span"
          >
            {t("common.upload")}
          </LoadingButton>
          <br />
          {errors.importCsv && (
            <Typography variant="caption" color="#fa541c">
              {errors.importCsv?.message?.toString()}
            </Typography>
          )}
        </label>
        {fileInput && (
          <Typography variant="body2">{fileInput.name}</Typography>
          )}
      </Stack>
    </Show>
  );
};