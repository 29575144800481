import {OpenNotificationParams} from "@pankod/refine-core";

export function createErrorNotification(t: Function, resourceNameCode: string): {
  errorNotification: (data?: unknown, values?: unknown, resource?: string|undefined) => OpenNotificationParams
} {
  return {
    errorNotification: (data: any, values: unknown, resource: string|undefined) => {
      let statusCode = 1;
      let message = 'Something went wrong';
      if (data?.response) {
        message = data?.response?.data?.errors;
        statusCode = data?.response?.status;
      }
      return {
        message: t(message),
        description: t("notifications.createError", {resource: t(resourceNameCode), statusCode: statusCode}),
        type: "error",
      }
    }
  }
}