import React from "react";
import {Chip, ChipTypeMap, Typography} from "@pankod/refine-mui";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import type { DateFieldProps } from "@pankod/refine-mui";
import {useGetLocale, useTranslate} from "@pankod/refine-core";
import {Event} from "@mui/icons-material";

dayjs.extend(LocalizedFormat);

const defaultLocale = dayjs.locale();

/**
 * This field is used to display dates. It uses {@link https://day.js.org/docs/en/display/format `Day.js`} to display date format and
 * Material UI {@link https://mui.com/material-ui/react-typography/#main-content `<Typography>`} component
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/mui/components/fields/date} for more details.
 */
export const DateField: React.FC<DateFieldProps & {component?: React.ElementType}> = ({
  value,
  locales,
  format: dateFormat = "L",
  component = 'p',
  variant= 'body2',
  ...rest
}) => {
  let locale = useGetLocale();
  const t = useTranslate();

  return (
    <Typography variant={variant} {...rest} component={component}>
      {value
        ? dayjs(value)
        // @ts-ignore
        .locale(locale || defaultLocale)
        .format(dateFormat)
        : t('common.notSet')}
    </Typography>
  );
}

export const ChipDateField: React.FC<DateFieldProps & ChipTypeMap["props"]> = ({
  value,
  locales,
  format: dateFormat = "L",
  size,
}) => {
  let locale = useGetLocale();
  const t = useTranslate();

  return (
    <Chip icon={<Event />} size={size} component="span" label={value
      ? dayjs(value)
        // @ts-ignore
        .locale(locale || defaultLocale)
        .format(dateFormat)
      : t('common.notSet')} />
  );
}
