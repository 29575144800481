import { useTranslate, HttpError } from "@pankod/refine-core";
import {
  Box,
  TextField,
  Autocomplete,
  useAutocomplete, MenuItem, Typography,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from "@mui/x-date-pickers";

import {IConsumer, IEmployee, IReference} from "interfaces";
import routerProvider from "@pankod/refine-react-router-v6";
import { Edit } from "components/crud/edit";
import React from "react";
import {localToUTC, makeLocalAppearUTC } from "components/fields/dateTimePicker";
import {renderFullNameShort} from "../../utils/dataRenderUtils";
import {createErrorNotification} from "../../utils/errorNotification";

const { useParams } = routerProvider;

interface IConsumerRouteParams {
  org_id: string;
}

export const ConsumerEdit: React.FC = () => {
  const t = useTranslate();
  const params: IConsumerRouteParams = useParams();
  const {
    refineCore: { queryResult, formLoading },
    saveButtonProps,
    register,
    control,
    formState: { errors },
  } = useForm<IConsumer, HttpError, IConsumer>({
    refineCoreProps: {
      resource: `organizations/${params.org_id}/consumers`,
      ...createErrorNotification(t, "consumers.titles.create"),
    },
  });

  const { autocompleteProps } = useAutocomplete<IEmployee>({
    resource: `organizations/${params.org_id}/employees`,
    hasPagination: true,
    defaultValue: queryResult?.data?.data.manager?.id,
    queryOptions: { enabled: true }, //!!queryResult?.data?.data.manager?.id },
    defaultValueQueryOptions: { enabled: false },
    debounce: 700,
  });

  const { autocompleteProps: autocompleteSourceProps } = useAutocomplete<IReference>({
    resource: `organizations/${params.org_id}/references`,
    hasPagination: true,
    defaultValue: queryResult?.data?.data.source?.id,
    queryOptions: { enabled: true }, //!!queryResult?.data?.data.source?.id },
    defaultValueQueryOptions: { enabled: false },
    debounce: 700,
    filters: [{
      field: 'type',
      operator: 'eq',
      value: 'customer.source',
    }, {
      field: 'archive',
      operator: 'eq',
      value: 0,
    }],
  });

  const { autocompleteProps: autocompleteCategoryProps } = useAutocomplete<IReference>({
    resource: `organizations/${params.org_id}/references`,
    hasPagination: true,
    defaultValue: queryResult?.data?.data.source?.id,
    queryOptions: { enabled: true }, //!!queryResult?.data?.data.category?.id },
    defaultValueQueryOptions: { enabled: false },
    debounce: 700,
    filters: [{
      field: 'type',
      operator: 'eq',
      value: 'customer.category',
    }, {
      field: 'archive',
      operator: 'eq',
      value: 0,
    }],
  });

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      // resource={'organizations/consumers'}
      // breadcrumb // @TODO This feature can be managed globally via the <Refine> component's options
      title={<Typography variant="h5">{t("consumers.titles.edit")}</Typography>} // @TODO this and next props should not be used, this data comes form resource
      canDelete={true}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("lastName")}
          margin="normal"
          fullWidth
          label={t("consumers.lastName")}
          name="lastName"
          autoFocus
        />

        <TextField
          {...register("firstName")}
          margin="normal"
          fullWidth
          label={t("consumers.firstName")}
          name="firstName"
        />

        <TextField
          {...register("middleName")}
          margin="normal"
          fullWidth
          label={t("consumers.middleName")}
          name="middleName"
        />

        <TextField
          {...register("phone", {
            required: t("errors.required.field", { field: t("customers.phone") }),
          })}
          error={!!errors.phone}
          helperText={errors.phone?.message}
          margin="normal"
          fullWidth
          label={t("customers.phone")}
          name="phone"
          required
        />

        <TextField
          {...register("comment")}
          margin="normal"
          fullWidth
          label={t("customers.comment")}
          name="comment"
        />

        <Controller
          control={control}
          name="conversionDateTime"
          render={({ field }) => (
            <DateTimePicker
              {...field}
              label={t("consumers.conversionDateTime")}
              value={makeLocalAppearUTC(field.value)}
              onChange={value => field.onChange({ target: { value: localToUTC(value) } })}
              renderInput={(params: any) => <TextField {...params} margin="normal" />}
            />
          )}
        />

        <Controller
          control={control}
          name="source"
          defaultValue={null as any}
          render={({ field }) => (
            <Autocomplete
              {...autocompleteSourceProps}
              {...field}
              freeSolo
              autoSelect
              includeInputInList
              autoHighlight={false}
              // clearOnEscape
              // clearOnBlur
              // selectOnFocus
              noOptionsText={t('common.searchNoItems')}
              onChange={(_, value) => {
                if (typeof value === 'string') {
                  field.onChange(value);
                  return;
                }
                field.onChange(value?.id || value);
              }}
              getOptionLabel={(item) => {
                if (typeof item === 'string') {
                  return item;
                }
                return item.visibleName ? item.visibleName : autocompleteSourceProps?.options?.find(
                  (p) => p.id.toString() === item.toString(),
                )?.visibleName ?? "";
              }}
              isOptionEqualToValue={(option, value: IReference|number) =>
                value === undefined || option.id.toString() === value.toString() || (typeof value === 'object' && option.id === value?.id)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("customers.source")}
                  margin="normal"
                  variant="outlined"
                  error={!!errors.source}
                  helperText={errors.source?.message}
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name="category"
          defaultValue={null as any}
          render={({ field }) => (
            <Autocomplete
              {...autocompleteCategoryProps}
              {...field}
              freeSolo
              autoSelect
              includeInputInList
              autoHighlight={false}
              // clearOnEscape
              noOptionsText={t('common.searchNoItems')}
              onChange={(_, value) => {
                if (typeof value === 'string') {
                  field.onChange(value);
                  return;
                }
                field.onChange(value?.id || value);
              }}
              getOptionLabel={(item) => {
                if (typeof item === 'string') {
                  return item;
                }
                return item.visibleName ? item.visibleName : autocompleteCategoryProps?.options?.find(
                  (p) => p.id.toString() === item.toString(),
                )?.visibleName ?? "";
              }}
              isOptionEqualToValue={(option, value: IReference|number) =>
                value === undefined || option.id.toString() === value.toString() || (typeof value === 'object' && option.id === value?.id)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("customers.category")}
                  margin="normal"
                  variant="outlined"
                  error={!!errors.category}
                  helperText={errors.category?.message}
                />
              )}
            />
          )}
        />

        <TextField
          {...register("email")}
          margin="normal"
          helperText={errors.email?.message}
          fullWidth
          label={t("customers.email")}
          name="email"
          type="email"
        />

        <Controller
          control={control}
          name="birthDate"
          render={({ field }) => (
            <DatePicker
              {...field}
              label={t("customers.birthDate")}
              value={makeLocalAppearUTC(field.value)}
              onChange={value => field.onChange({ target: { value: localToUTC(value) } })}
              renderInput={(params: any) => <TextField {...params} margin="normal" />}
            />
          )}
        />

        <Controller
          control={control}
          name="gender"
          defaultValue={""}
          render={({ field }) => (
            <TextField
              {...field}
              margin="normal"
              fullWidth
              label={t("customers.gender")}
              select={true}
              value={field.value ?? ""}
            >
              <MenuItem value="">{t('common.notSelected')}</MenuItem>
              <MenuItem value="male">{t('entity.gender_male')}</MenuItem>
              <MenuItem value="female">{t('entity.gender_female')}</MenuItem>
            </TextField>
          )}
        />

        <TextField
          {...register("city")}
          margin="normal"
          fullWidth
          label={t("customers.city")}
        />

        <Controller
          control={control}
          name="status"
          rules={{
            required: t("errors.required.field", { field: t("customers.status") }),
          }}
          defaultValue={1}
          render={({ field }) => (
            <TextField
              {...field}
              margin="normal"
              fullWidth
              label={t("customers.status")}
              select={true}
              required
              error={!!errors.phone}
              helperText={errors.phone?.message}
            >
              <MenuItem value={1}>{t('consumers.status_1')}</MenuItem>
              <MenuItem value={0}>{t('consumers.status_0')}</MenuItem>
            </TextField>
          )}
        />

        <Controller
          control={control}
          name="manager"
          defaultValue={control._defaultValues.manager?.id || null as any}
          render={({ field }) => (
            <Autocomplete
              {...autocompleteProps}
              {...field}
              clearOnEscape
              noOptionsText={t('common.searchNoItems')}
              onChange={(_, value) => {
                field.onChange(value?.id || value);
              }}
              getOptionLabel={(item) => {
                return renderFullNameShort((item.lastName || item.firstName) //@TODO check if any property exists (ex. id or lastName)
                  ? item
                  : autocompleteProps?.options?.find(
                    (p) =>
                      p.id.toString() ===
                      item.toString(),
                  )) ?? "";
              }}
              isOptionEqualToValue={(option, value: IEmployee|number) =>
                value === undefined || option.id.toString() === value.toString() || (typeof value === 'object' && option.id === value?.id)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("customers.manager")}
                  margin="normal"
                  variant="outlined"
                  error={!!errors.manager}
                  helperText={errors.manager?.message}
                />
              )}
            />
          )}
        />
      </Box>
    </Edit>
  );
};
