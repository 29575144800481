import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@pankod/refine-mui";
import React from "react";
import {useTranslate} from "@pankod/refine-core";

interface ConfirmDialogProps {
  open: boolean;
  dialogTitle: React.ReactNode;
  dialogContent?: React.ReactNode;
  agreeText?: string;
  disagreeText?: string;
  handleClose: (event: any) => void;
  handleAgree: (event: any) => void;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  dialogTitle,
  dialogContent,
  agreeText,
  disagreeText,
  handleClose,
  handleAgree
}) => {

  const t = useTranslate();

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            {dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>{disagreeText || t("buttons.cancel")}</Button>
          <Button onClick={handleAgree}>{agreeText || t("common.yes")}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}