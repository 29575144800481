import routerProvider from "@pankod/refine-react-router-v6";
import React, {useEffect} from "react";
import {useGetLocale, useInvalidate, useTranslate} from "@pankod/refine-core";
import {
  Autocomplete, Avatar,
  DataGrid,
  DeleteButton,
  Divider,
  EditButton,
  getGridNumericOperators,
  getGridStringOperators,
  Grid,
  GridColumns, GridToolbarColumnsButton,
  GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton,
  ruRU,
  ShowButton,
  Stack, TextField, Tooltip, useAutocomplete,
  useDataGrid
} from "@pankod/refine-mui";
import {IDeal, IDealFull, IEmployee} from "../../interfaces";
import {
  renderDateTime,
  renderFullNameLong,
  renderFullNameShort,
  renderInitials,
} from "../../utils/dataRenderUtils";
import {List} from "../../components/crud/list";
import {DealEditModal, IDealRouteParams} from "./index";
// import {ActionDialogList} from "../customerActions/dialogList";
import {useModalForm} from "@pankod/refine-react-hook-form";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {createErrorNotification} from "../../utils/errorNotification";

const { useParams, Link } = routerProvider;

export const DealsList: React.FC = () => {
  const t = useTranslate();
  const locale = useGetLocale();
  const invalidate = useInvalidate();
  const params: IDealRouteParams = useParams();
  const [filterEmployeeId, setFilterEmployeeId] = React.useState(undefined);
  const [filterMonth, setFilterMonth] = React.useState(dayjs().format('YYYY-MM-01'));

  const {dataGridProps, search, setFilters, filters} = useDataGrid<IDealFull>({
    resource: `organizations/${params.org_id ?? 0}/deals`,
    defaultSetFilterBehavior: "merge",
    initialSorter: [{
      field: "id",
      order: "desc"
    }],
    // syncWithLocation: true,
  });

  useEffect(() => {
    setFilters([{
      field: "executor",
      operator: "eq",
      value: filterEmployeeId
    }, {
      field: "startDate",
      operator: "eq",
      value: filterMonth
    }]);
  }, [filterEmployeeId, filterMonth]);

  const stringColumnFilterOperations = {
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === 'contains',
    )
  };
  const numberColumnFilterOperations = {
    filterOperators: getGridNumericOperators().filter(
      (operator) => operator.value === '=',
    )
  };

  const columns = React.useMemo<GridColumns<IDealFull>>(
    () => [
      {
        ...numberColumnFilterOperations,
        field: "id",
        headerName: t("entity.id"),
        type: "number",
        width: 65,
        filterable: false
      },
      {
        ...stringColumnFilterOperations,
        field: "status",
        headerName: t("deals.status"),
        maxWidth: 150,
        flex: 1,
        hideable: false,
        valueGetter: params => t(`deals.status_${params.row.status}`),
      },
      {
        field: "consumer",
        headerName: t("deals.consumer"),
        maxWidth: 200,
        flex: 1,
        filterable: false,
        renderCell: ({row}) => {
          const name = renderFullNameShort(row?.consumer);
          return <Link to={`/organizations/${params.org_id}/consumers/show/${row.consumer?.id}`}>{name !== '' ? name : row.consumer?.id}</Link>
        }
      },
      {
        field: "action",
        headerName: t("deals.action"),
        flex: 1,
        renderCell: ({row}) => {
          if (!row.action) {
            return '';
          }
          return (<>
            {row.action.executor && <Tooltip title={`${t("customers.actions.executor")}: ${renderFullNameLong(row.action.executor)}`}>
              <Avatar sx={{ width: 24, height: 24, fontSize: 12 }}>{renderInitials(row.action.executor)}</Avatar>
            </Tooltip>}
            {row.action.description}: {renderDateTime(row.action.dateTime, locale(), "DD.MM.YY HH:mm")}
          </>);
        }
      },
      {
        field: "comment",
        headerName: t("deals.comment"),
        minWidth: 100,
        flex: 1,
        filterable: false,
      },
      {
        field: "amount",
        type: "number",
        headerName: t("deals.amount"),
        maxWidth: 150,
        flex: 1,
        valueGetter: ({row}) => {
          let sum = 0.00;
          row.serviceItems.forEach((item) => {sum += parseFloat(item.customTotalPrice ?? "0.00");});
          return sum.toFixed(2);
        }
      },
      {
        field: "tactions",
        type: "actions",
        width: 100,
        headerName: t("table.actions"),
        renderCell: function render({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              {/*{!row.action && <Button onClick={() => openDialog(row)} startIcon={<AddLink />} title={t('deals.linkWithAction')} size="small" variant="contained">{t('deals.linkWithAction')}</Button>}*/}
              <ShowButton size="small" disabled hideText recordItemId={row.id} />
              <EditButton size="small" hideText onClick={() => {showEditModal(row.id)}} />
              <DeleteButton size="small" disabled hideText recordItemId={row.id} resourceNameOrRouteName={`organizations/${params.org_id}/deals`} />
            </Stack>
          );
        },
      },
    ],
    [t]
  );

  // const [open, setOpen] = React.useState(false);
  // const [linkDeal, setLinkDeal] = React.useState<IDealFull | null>();
  // const openDialog = (deal: IDealFull) => {
  //   setOpen(true);
  //   setLinkDeal(deal);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleSave = (newValue?: any) => {
  //   setOpen(false);
  //
  //   if (newValue && linkDeal) {
  //     linkAction(linkDeal?.id, newValue);
  //   }
  // };
  // const { mutate: mutateDeal } = useUpdate<IDeal>();
  // function linkAction(dealId: number, actionId: number) {
  //   mutateDeal({
  //     resource: `organizations/${params.org_id}/deals`,
  //     id: dealId,
  //     values: { action: actionId },
  //   }, {
  //     onSuccess: () => {
  //       // invalidate({
  //       //   resource: `organizations/${params.org_id}/deals`,
  //       //   invalidates: ["detail"],
  //       //   id: params.id,
  //       // });
  //     }
  //   })
  // }

  const editModalFormReturnValues = useModalForm<IDeal>({
    refineCoreProps: {
      resource: `organizations/${params.org_id}/deals`,
      action: "edit",
      redirect: false,
      onMutationSuccess: (data) => {
        invalidate({
          resource: `organizations/${params.org_id}/deals`,
          invalidates: ["detail"],
          id: data.data.id,
        });
      },
      ...createErrorNotification(t, "deals.titles.edit"),
    },
  });

  const {
    modal: { show: showEditModal },
  } = editModalFormReturnValues;

  return (
    <List
      title={t("deals.titles.list")}
      canCreate={true}
    >
      {/*<ActionDialogList open={open} deal={linkDeal} orgId={parseInt(params.org_id)} onSave={handleSave} onCancel={handleClose} />*/}
      <DealEditModal {...editModalFormReturnValues} />
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 60}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        onFilterModelChange={(model, details) => {
          if (model.items.length && model.items[0]?.value === undefined) {
            // @TODO This is a rude patch for condition while grid filter is removed (value undefined) and filter behaviour is merge.
            // Without this fix grid would not update while removing filter from its column
            setFilters([{
              field: model.items[0].columnField,
              operator: 'eq',
              value: model.items[0].value,
            }]);
          }
          return dataGridProps.onFilterModelChange(model, details);
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500, onChange: (_: any, value: string) => search(value)},
            filterEmployeeId,
            setFilterEmployeeId,
            filterMonth,
            setFilterMonth
          },
        }}
        components={{
          Toolbar: CustomGridToolbar,
        }}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
        }}
      />
    </List>
  );
}

const CustomGridToolbar = ({filterEmployeeId, setFilterEmployeeId, filterMonth, setFilterMonth}: {
  filterEmployeeId: number,
  setFilterEmployeeId: (id?: number) => void,
  filterMonth: string,
  setFilterMonth: (month: string) => void
}): JSX.Element => {
  const t = useTranslate();
  const urlParams: IDealRouteParams = useParams();
  const { autocompleteProps } = useAutocomplete<IEmployee>({
    resource: `organizations/${urlParams.org_id}/employees`,
    hasPagination: true,
    queryOptions: { enabled: true },
    defaultValueQueryOptions: { enabled: false },
    debounce: 700,
  });

  return (
    <GridToolbarContainer>
      <Grid container>
        <Grid item sm={3}>
          <Autocomplete
            {...autocompleteProps}
            autoSelect={true}
            noOptionsText={t('common.searchNoItems')}
            defaultValue={autocompleteProps.options.find((i) => i.id === filterEmployeeId)}
            size="small"
            onChange={(_, value) => {
              setFilterEmployeeId(value?.id);
            }}
            getOptionLabel={(item) => {
              return renderFullNameShort(item.lastName
                ? item
                : autocompleteProps?.options?.find(
                  (p) =>
                    p.id.toString() ===
                    item.toString(),
                )) ?? "";
            }}
            isOptionEqualToValue={(option, value: IEmployee|number) =>
              value === undefined || option.id.toString() === value.toString() || (typeof value === 'object' && option.id === value?.id)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("sideContextMenu.reportMonthly.employeeFilter")}
                margin="normal"
                variant="standard"
              />
            )}
          />
        </Grid>
        <Grid item sm={2}>
          <DatePicker
            views={['month', 'year']}
            label={t("sideContextMenu.reportMonthly.dateFilter")}
            value={filterMonth}
            onChange={value => setFilterMonth(dayjs(value).format('YYYY-MM-01'))}
            renderInput={(params: any) => <TextField {...params} margin="normal" size="small" variant="standard"/>}
          />
        </Grid>
        <Divider orientation="vertical" variant="middle" flexItem light/>
        <Grid item sm>
          <GridToolbarFilterButton />
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}