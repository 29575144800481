import {
    GridToolbarContainer,
    // GridToolbarDensitySelector,
    GridToolbarQuickFilter,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    // Button,
    // GridAddIcon
} from "@pankod/refine-mui";

export default function CustomGridToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter />
      <GridToolbarFilterButton />
      <GridToolbarColumnsButton />
      {/*<GridToolbarDensitySelector />*/}
    </GridToolbarContainer>
  );
}