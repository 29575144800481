import routerProvider from "@pankod/refine-react-router-v6";
import React from "react";
import {useTranslate} from "@pankod/refine-core";
import {
  DataGrid,
  DeleteButton,
  EditButton,
  GridColumns,
  ruRU,
  ShowButton,
  Stack,
  useDataGrid
} from "@pankod/refine-mui";
import {IServiceType} from "../../interfaces";
import {List} from "../../components/crud/list";
import {IServiceTypeRouteParams} from "./index";

const { useParams } = routerProvider;

export const ServiceTypeList: React.FC = () => {
  const t = useTranslate();

  const params: IServiceTypeRouteParams = useParams();
  const {dataGridProps} = useDataGrid<IServiceType>({
    resource: `organizations/${params.org_id ?? 0}/services`,
  });

  const columns = React.useMemo<GridColumns<IServiceType>>(
    () => [
      {
        field: "id",
        headerName: t("entity.id"),
        type: "number",
        width: 50,
      },
      {
        field: "title",
        headerName: t("organizationServices.title"),
        minWidth: 100,
        flex: 1,
        hideable: false,
      },
      {
        field: "description",
        headerName: t("organizationServices.description"),
        minWidth: 100,
        flex: 1,
        hideable: false,
      },
      {
        field: "duration",
        headerName: t("organizationServices.duration"),
        minWidth: 100,
        flex: 1,
        hideable: false,
        valueGetter: params => `${params.row.duration / 60} ${t("common.time.minShort")}`
      },
      {
        field: "cost",
        headerName: t("organizationServices.cost"),
        minWidth: 100,
        flex: 1,
        hideable: false,
        valueGetter: params => `${params.row.cost} ${params.row.currency}`
      },
      {
        field: "tactions",
        type: "actions",
        headerName: t("table.actions"),
        renderCell: function render({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <ShowButton size="small" hideText recordItemId={row.id} />
              <EditButton size="small" hideText recordItemId={row.id} resourceNameOrRouteName={`organizations/${params.org_id}/services`} />
              <DeleteButton size="small" hideText disabled recordItemId={row.id} resourceNameOrRouteName={`organizations/${params.org_id}/services`} />
            </Stack>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 100,
      },
    ],
    [t]
  );

  return (
    <List
      title={t("organizationServices.titles.list")}
      canCreate={true}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 60}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
        }}
      />
    </List>
  );
}
