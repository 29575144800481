import React, {useMemo, useRef} from "react";

import {Card, CardContent, CardHeader, Checkbox, FormControlLabel, Typography} from "@pankod/refine-mui";
import {IReportFinanceOperations} from "./index";
import {pieArcLabelClasses, PieChart} from '@mui/x-charts/PieChart';
import {useGetLocale, useTranslate} from "@pankod/refine-core";
import {renderCurrency} from "../../utils/dataRenderUtils";

interface FinanceReportOperationCardProps {
  title: string;
  data: Array<IReportFinanceOperations>
}

export const FinanceReportOperationsChart: React.FC<FinanceReportOperationCardProps> = ({title, data}) => {
  const locale = useGetLocale();
  const t = useTranslate();
  let total = useRef<Record<string, number>>({});

  const [isLegendHidden, setIsLegendHidden] = React.useState(false);

  const labelClip = (label: string) => {
    if (label.length > 25) {
      return label.substring(0, 25) + '...';
    }
    return label;
  }

  const getArcLabel = (value: number, currency: string|null): string => {
    if (!currency) {
      return '';
    }
    const percent = value / total.current[currency];
    return percent > 0.04 ? `${(percent * 100).toFixed(0)}%` : '';
  };

  const formatValue = (value: number, currency: string|null): string => {
    let result = renderCurrency(value, locale(), currency);
    if (currency) {
      const percent = value / total.current[currency];
      result = result + ` (${(percent * 100).toFixed(0)}%)`;
    }
    return result;
  };

  const operationsData = useMemo(() => {
    total.current = {};
    if (!data?.length) {
      return [];
    }

    return data
      .map((group) => {
        total.current[String(group.currency)] = (total.current[String(group.currency)] ?? 0.0) + Number(group.sum_amount);
        return ({
          label: (group.category || group.payee || null) ?? t('common.undefined'),
          value: Number(group.sum_amount),
          currency: group.currency,
        });
      })
      .filter(
        (stage) =>
          stage.value !== null && stage.value !== undefined && stage.value > 0,
      )
      .sort((a, b) => (b.value ?? 0) - (a.value ?? 0));
  }, [data]);

  return (
    <Card>
      <CardHeader
        title={<div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography style={{ marginLeft: ".5rem" }}>{title} {Object.keys(total.current).map(function(k: string) {
            return (<span key={k}> {renderCurrency(total.current[k], locale(), k)} </span>);
          })}</Typography>
          <FormControlLabel
            checked={isLegendHidden}
            control={
              <Checkbox onChange={(event) => setIsLegendHidden(event.target.checked)} />
            }
            label={t('common.hideLegend')}
            labelPlacement="end"
          />
        </div>} />
      <CardContent sx={{padding: 1}}>
        <PieChart
          series={[
            {
              paddingAngle: 0.5,
              innerRadius: 70,
              outerRadius: 115,
              cx: 125,
              // cy: 100,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 50, additionalRadius: -20, color: 'gray' },
              data: operationsData,
              arcLabel: (datum) => getArcLabel(datum.value, 'currency' in datum ? String(datum.currency) : null),
              valueFormatter: (datum) => formatValue(datum.value, 'currency' in datum ? String(datum.currency) : null),
            },
          ]}
          slotProps={{
            legend: {
              hidden: isLegendHidden,
              labelStyle: {
                fontSize: 12,
                width: 200,
              },
            },
          }}
          height={400}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'white',
              fontSize: 14,
            },
          }}
        >
          {/*<PieCenterLabel>{ renderCurrency(total, locale(), ) }</PieCenterLabel>*/}
        </PieChart>
      </CardContent>
    </Card>
  );
};