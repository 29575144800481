import {IOrganization} from "../../interfaces";
import {useShow, useTranslate} from "@pankod/refine-core";
import {
  Stack,
  Typography
} from "@pankod/refine-mui";
import { Show } from "components/crud/show";

export const OrganizationShow: React.FC = () => {
  const { queryResult } = useShow<IOrganization>();
  const t = useTranslate();

  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          {t("organizations.shortName")}
        </Typography>
        <Typography variant="body2">{record?.shortName}</Typography>
        <Typography variant="body1" fontWeight="bold">
          {t("organizations.fullName")}
        </Typography>
        <Typography variant="body2">{record?.fullName}</Typography>
        <Typography variant="body1" fontWeight="bold">
          {t("entity.uuid")}
        </Typography>
        <Typography variant="body2">{record?.uuid}</Typography>
      </Stack>
    </Show>
  );
};