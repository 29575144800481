import React, {useMemo, useRef} from "react";

import {Card, CardContent, CardHeader, Typography} from "@pankod/refine-mui";
import {IReportCutomersStatistics} from "./index";
import {useTranslate} from "@pankod/refine-core";
import {BarChart} from "@mui/x-charts";
import dayjs from "dayjs";

interface CustomersStatisticsCardProps {
  data: Array<IReportCutomersStatistics>|undefined,
  setDateFromFilter: Function,
  setDateToFilter: Function,
}

// interface Datum {
//   count: number,
//   source: string,
//   month: number,
//   year: number,
//   monthName: string,
// }

export const CustomersStatisticsMonthlyBars: React.FC<CustomersStatisticsCardProps> = ({data, setDateFromFilter, setDateToFilter}) => {
  const t = useTranslate();
  let datumIndexes = useRef<string[]>([]);

  const operationsData = useMemo(() => {
    let result: Record<string, Record<string, any>> = {};
    let resultArray: any[] = [];

    if (!data?.length) {
      return [];
    }

    data.forEach((value) => {
      if (value.month) {

        const date = dayjs().month(value.month - 1);
        if (value.year) {
          date.year(value.year);
        }
        const monthName = date.format('MMM YYYY');

        let datum: Record<string, any> = result[monthName] ?? {
          source: value.source,
          month: value.month,
          year: value.year,
          monthName: monthName,
        };

        if (value.source) {
          datum[value.source] = Number(value.count);
          if (!datumIndexes.current.includes(value.source)) {
            datumIndexes.current.push(value.source);
          }
        }

        result[monthName] = datum;
      }
    });

    for (const prop in result) {
      resultArray.push(result[prop]);
    }

    return resultArray
      .sort((a, b) => (a.year - b.year) * 100  + (a.month - b.month));

  }, [data]);

  const setFiltersPeriod = (d: any) => {
    if (d.axisValue) {
      const date = dayjs(d.axisValue.toLowerCase(), 'MMM YYYY');
      setDateFromFilter(date.format('YYYY-MM-01'));
      setDateToFilter(date.endOf('month').format('YYYY-MM-DD'));
    }
  };

  if (operationsData.length === 0) {
    return <></>;
  }

  return (
    <Card>
      <CardHeader
        title={<div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography style={{ marginLeft: ".5rem" }}>{t('reports.customersStatistics.newBySources')}</Typography>
        </div>} />
      <CardContent sx={{padding: 1}}>
        <BarChart  xAxis={[{ scaleType: 'band', dataKey: 'monthName' }]}
                   yAxis={[{ scaleType: 'linear'}]}
                   margin={{left: 80}}
                   dataset={operationsData}
                   series={datumIndexes.current.map((value) => { return {'dataKey': value, 'label': value}; } )}
                   height={300}
                   onAxisClick={(event, d) => setFiltersPeriod(d)}
        />
      </CardContent>
    </Card>
  );
};