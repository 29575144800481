import React, {useMemo} from "react";

import {Card, CardContent, CardHeader, Typography} from "@pankod/refine-mui";
import {IReportFinanceOperations} from "./index";
import {useTranslate} from "@pankod/refine-core";
import {ChartsReferenceLine, LineChart} from "@mui/x-charts";
import dayjs from "dayjs";

interface FinanceReportOperationCardProps {
  data: Array<IReportFinanceOperations>|undefined,
}

interface Datum {
  value: number,
  currency: string|null,
  month: number,
  year: number,
  monthName: string,
}

export const FinanceReportOperationsMonthlyLine: React.FC<FinanceReportOperationCardProps> = ({data}) => {
  const t = useTranslate();

  const operationsData = useMemo(() => {
    let result: Record<string, Datum> = {};
    let resultArray: any[] = [];

    if (!data?.length) {
      return [];
    }

    data.forEach((value) => {
      if (value.month) {

        const date = dayjs().month(value.month - 1);
        if (value.year) {
          date.year(value.year);
        }
        const monthName = date.format('MMM YYYY');

        let datum = result[monthName] ?? {
          currency: value.currency,
          month: value.month,
          year: value.year,
          monthName: monthName,
          value: 0,
        };

        if (value.type === 'Income') {
          datum.value = datum.value + Number(value.sum_amount);
        } else {
          datum.value = datum.value - Number(value.sum_amount);
        }

        result[monthName] = datum;
      }
    });

    for (const prop in result) {
      resultArray.push(result[prop]);
    }

    return resultArray
      .sort((a, b) => (a.year - b.year) * 100  + (a.month - b.month));

  }, [data]);

  if (operationsData.length === 0) {
    return <></>;
  }

  return (
    <Card>
      <CardHeader
        title={<div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography style={{ marginLeft: ".5rem" }}>{t('reports.financeOperations.profitAndLoss')}</Typography>
        </div>} />
      <CardContent sx={{padding: 1}}>
        <LineChart xAxis={[{ scaleType: 'point', dataKey: 'monthName' }]}
                   yAxis={[{
                     dataKey: 'value',
                     colorMap: {
                       type: 'piecewise',
                       thresholds: [0],
                       colors: ['red', 'green'],
                       // type: 'continuous',
                       // min: -100000,
                       // max: 100000,
                       // color: ['red', 'green'],
                     },
                   }]}
                   margin={{left: 80}}
                   dataset={operationsData}
                   series={[
                     { dataKey: 'value' },
                   ]}
                   height={300}
        >
          <ChartsReferenceLine y={0} />
        </LineChart>
      </CardContent>
    </Card>
  );
};