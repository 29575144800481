import {IDeal, IServiceItem} from "../../interfaces";
import {useInvalidate, useTranslate} from "@pankod/refine-core";
import {Close, Done, Edit, MoreVert, Payment, Work} from "@mui/icons-material";
import {lightBlue, orange, grey, deepOrange} from "@mui/material/colors";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader, Divider, IconButton, Menu, MenuItem,
  MuiList,
  Tooltip,
} from "@pankod/refine-mui";
import React from "react";
import routerProvider from "@pankod/refine-react-router-v6";
import { IConsumerRouteParams } from "pages/consumers";
import {useModalForm} from "@pankod/refine-react-hook-form";
import {ConfirmDialog} from "../../components/feedback/confirmDialog";
import {
  DEAL_STATUS_CANCELED,
  DEAL_STATUS_CLOSED,
  DEAL_STATUS_DRAFT,
  DEAL_STATUS_PAYMENT_PENDING,
  DEAL_STATUS_SERVING
} from "../../constants/deal";
import {useBasicMenuState} from "../../components/navigation/basicMenu";
import {DealEditModal} from ".";
import {DealListItem, ServiceItemCreateModal} from "pages/dealServiceItems";

interface DealCardProps {
  deal: IDeal;
}

const { useParams } = routerProvider;

export const DealCard: React.FC<DealCardProps> = ({deal}) => {
  const t = useTranslate();
  const params: IConsumerRouteParams = useParams();

  const {handleClose: handleMenuClose, handleClick: handleMenuClick, anchorEl: menuAnchorEl} = useBasicMenuState();
  const invalidate = useInvalidate();

  const editModalFormReturnValues = useModalForm<IDeal>({
    refineCoreProps: {
      resource: `organizations/${params.org_id}/deals`,
      action: "edit",
      redirect: false,
      onMutationSuccess: () => {
        invalidate({
          resource: `organizations/${params.org_id}/consumers`,
          invalidates: ["detail"],
          id: params.id,
        });
      },
    },
  });

  const createServiceItemModalFormReturnValues = useModalForm<IServiceItem>({
    refineCoreProps: {
      resource: `organizations/${params.org_id}/consumers/${params.id}/deals/${deal.id}/services`,
      action: "create",
      redirect: false,
      onMutationSuccess: () => {
        invalidate({
          resource: `organizations/${params.org_id}/consumers`,
          invalidates: ["detail"],
          id: params.id,
        });
      },
    },
    // @TODO set default values to other forms
    defaultValues: {
      "customTotalPrice": null,
      "comment": null,
      "executionPlace": null,
    }
  });

  const {
    modal: { show: showEditModal },
  } = editModalFormReturnValues;

  const {
    modal: { show: showCreateServiceItemModal },
  } = createServiceItemModalFormReturnValues;

  const [openDealStatusConfirm, setOpenDealStatusConfirm] = React.useState<boolean>(false);
  const handleCloseDealStatusConfirm = () => {
    setOpenDealStatusConfirm(false);
  };

  let icon = <></>;
  let iconBg;

  switch (deal.status) {
    case DEAL_STATUS_DRAFT:
      icon = <Edit/>;
      iconBg = lightBlue[500];
      break;
    case DEAL_STATUS_PAYMENT_PENDING:
      icon = <Payment/>;
      iconBg = orange[500]
      break;
    case DEAL_STATUS_SERVING:
      icon = <Work/>;
      iconBg = deepOrange[500]
      break;
    case DEAL_STATUS_CLOSED:
      icon = <Done/>;
      iconBg = grey[500];
      break;
    case DEAL_STATUS_CANCELED:
      icon = <Close/>;
      iconBg = grey[500];
      break;
  }

  return (
    <Card sx={{ minWidth: 300, maxWidth: 450 }}>
      <DealEditModal {...editModalFormReturnValues} />
      <ServiceItemCreateModal {...createServiceItemModalFormReturnValues} />
      <ConfirmDialog
        open={openDealStatusConfirm}
        dialogTitle={t("deals.makeStatusClosedDialog")}
        dialogContent={t("deals.makeStatusClosedDialogContent")}
        handleClose={handleCloseDealStatusConfirm}
        handleAgree={handleCloseDealStatusConfirm}
      />
      <CardHeader
        title={deal.comment}
        avatar={<Tooltip title={t("deals.status_" + deal.status)}>
          <Avatar sx={{ bgcolor: iconBg }}>
            {icon}
          </Avatar>
        </Tooltip>}
        action={<React.Fragment>
          <IconButton
            id={`${deal.id}-menu-button`}
            aria-controls={Boolean(menuAnchorEl) ? `${deal.id}-action-menu` : undefined}
            aria-haspopup="true"
            aria-expanded={Boolean(menuAnchorEl) ? 'true' : undefined}
            onClick={handleMenuClick}
            aria-label="more"
          >
            <MoreVert />
          </IconButton>
          <Menu
            id={`${deal.id}-action-menu`}
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
            MenuListProps={{
            'aria-labelledby': `${deal.id}-menu-button`,
          }}>
            {deal.status !== DEAL_STATUS_CLOSED && <MenuItem key={0} onClick={() => {handleMenuClose(); showCreateServiceItemModal();}}>{t("deals.addItem")}</MenuItem>}
            <MenuItem key={1} onClick={() => {handleMenuClose(); showEditModal(deal.id)}}>{t("common.edit")}</MenuItem>
            {deal.status !== DEAL_STATUS_CLOSED && <MenuItem key={2} onClick={handleMenuClose}>{t("deals.makeStatusClosed")}</MenuItem>}
          </Menu>
        </React.Fragment>}
      />
      <CardContent sx={{padding: 0}}>
        <MuiList>
          {deal.serviceItems.map(value => {
            return <React.Fragment>
              <DealListItem key={value.id} dealItem={value} deal={deal} setOpenDealStatusConfirm={setOpenDealStatusConfirm} />
              <Divider key={value.id + "divider"} variant="inset" light component="li" />
            </React.Fragment>
          })}
        </MuiList>
      </CardContent>
      <CardActions>
        {deal.status !== DEAL_STATUS_CLOSED && <Button size="small" onClick={() => showCreateServiceItemModal()}>{t("deals.addItem")}</Button>}
      </CardActions>
    </Card>
  );
}
