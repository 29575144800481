import {TextField} from "@pankod/refine-mui";
import {DateTimePicker as MDTP, DatePicker as MDP} from "@mui/x-date-pickers";
import React from "react";
import {useGetLocale} from "@pankod/refine-core";
import dayjs, {Dayjs, isDayjs} from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("UTC");

export const makeLocalAppearUTC = (value: Date|string|null): Date|null => {
  if (!value) {
    return null;
  }
  const utcFromLocal = dayjs(value);
  return utcFromLocal.tz(dayjs.tz.guess(), true).toDate();
};

export const localToUTC = (dateTime: Dayjs|Date|null) => {
  if (isDayjs(dateTime))
    return dateTime.tz('UTC', true);
  else
    return dateTime;
};

export const DateTimePicker: React.FC<any> = ({
  value,
  onChange,
  ...rest
}) => {
  const language = useGetLocale();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
    <MDTP
      {...rest}
      value={makeLocalAppearUTC(value)}
      onChange={(value: Dayjs|Date|null) => onChange({ target: { value: localToUTC(value) } })}
      renderInput={(params: any) => <TextField {...params} margin="normal" />}
    />
    </LocalizationProvider>
  );
}

export const DatePicker: React.FC<any> = ({
  field,
  ...rest
}) => {
  const language = useGetLocale();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
    <MDP
      {...field}
      // label={t("consumers.conversionDateTime")}
      value={makeLocalAppearUTC(field.value)}
      onChange={(value: Dayjs|Date|null) => field.onChange({ target: { value: localToUTC(value) } })}
      renderInput={(params: any) => <TextField {...params} margin="normal" />}
    />
    </LocalizationProvider>
  );
}