import {useTranslate, HttpError} from "@pankod/refine-core";
import {
  Box, MenuItem,
  TextField,
} from "@pankod/refine-mui";
import {Controller, useForm} from "@pankod/refine-react-hook-form";

import {IFinanceOperation} from "interfaces";
import React from "react";
import {Typography} from "@mui/material";
import {createErrorNotification} from "../../utils/errorNotification";
import routerProvider from "@pankod/refine-react-router-v6";
import {IFinanceOperationRouteParams} from "./index";
import {Edit} from "../../components/crud/edit";
import {DateTimePicker} from "@mui/x-date-pickers";
import {localToUTC, makeLocalAppearUTC} from "../../components/fields/dateTimePicker";
import {
  FINANCE_OPERATION_EXPENSE,
  FINANCE_OPERATION_INCOME,
  FINANCE_OPERATION_TRANSFER
} from "../../constants/financeOperation";

const { useParams } = routerProvider;

export const FinanceOperationEdit: React.FC = () => {
  const t = useTranslate();
  const params: IFinanceOperationRouteParams = useParams();

  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    control,
    formState: { errors },
  } = useForm<IFinanceOperation, HttpError, IFinanceOperation>({
    refineCoreProps: {
      resource: 'operations',
      id: params.id,
      ...createErrorNotification(t, "financeOperations.titles.create"),
    },
  });

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title={<Typography variant="h5">{t("financeOperations.titles.edit")}</Typography>}
      canDelete={true}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Controller
          control={control}
          name="type"
          rules={{
            required: t("errors.required.field", {field: t("financeOperations.type")}),
          }}
          defaultValue={FINANCE_OPERATION_EXPENSE}
          render={({field}) => (
            <TextField
              {...field}
              margin="normal"
              fullWidth
              label={t("financeOperations.type")}
              select={true}
              required
              error={!!errors.type}
              // @ts-ignore
              helperText={errors.type?.message}
            >
              <MenuItem value={FINANCE_OPERATION_EXPENSE}>{t('financeOperations.type_' + FINANCE_OPERATION_EXPENSE)}</MenuItem>
              <MenuItem value={FINANCE_OPERATION_INCOME}>{t('financeOperations.type_' + FINANCE_OPERATION_INCOME)}</MenuItem>
              <MenuItem value={FINANCE_OPERATION_TRANSFER}>{t('financeOperations.type_' + FINANCE_OPERATION_TRANSFER)}</MenuItem>
            </TextField>
          )}
        />
        <TextField
          {...register("amount", {
            required: t("errors.required.field", {
              field: t("financeOperations.amount"),
            }),
            pattern: {
              value: /^[0-9]{0,13}[.,]?[0-9]{0,2}$/,
              message: t("errors.pattern.decimal"),
            },
          })}
          margin="normal"
          fullWidth
          label={t("financeOperations.amount")}
          name="amount"
          inputProps={{ inputMode: 'numeric', pattern: '^[0-9]{0,13}[.,]?[0-9]{0,2}$' }}
          required
          error={!!errors.amount}
          //@ts-ignore
          helperText={errors.amount?.message}
        />
        <Controller
          control={control}
          name="date"
          rules={{
            required: t("errors.required.field", { field: t("financeOperations.date") }),
          }}
          render={({ field }) => (
            <DateTimePicker
              {...field}
              label={t("financeOperations.date")}
              value={makeLocalAppearUTC(field.value)}
              onChange={value => field.onChange({ target: { value: localToUTC(value) } })}
              renderInput={(params: any) => <TextField
                {...params}
                error={!!errors.date}
                helperText={errors.date?.message}
                margin="normal"
                required
              />}
            />
          )}
        />
        <TextField
          {...register("category")}
          error={!!errors.category}
          helperText={errors.category?.message}
          margin="normal"
          fullWidth
          label={t("financeOperations.category")}
          name="category"
        />
        <TextField
          {...register("payee")}
          error={!!errors.payee}
          helperText={errors.payee?.message}
          margin="normal"
          fullWidth
          label={t("financeOperations.payee")}
          name="payee"
        />
        <TextField
          {...register("description")}
          error={!!errors.description}
          helperText={errors.description?.message}
          margin="normal"
          fullWidth
          label={t("financeOperations.description")}
          name="description"
        />
      </Box>
    </Edit>
  );
};
