import React, { useContext, useEffect } from "react";
import {useTranslate, useRouterContext} from "@pankod/refine-core";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  List,
  Stack,
  EditButton,
  DeleteButton,
  ButtonGroup, Button, ruRU, ShowButton, GridSelectionModel,
} from "@pankod/refine-mui";

import { IOrganization } from "interfaces";
import {SimplePopper} from "../../components/simplePopper";
import {OrganizationContext} from "../../contexts/organizationContext";

export const OrganizationList: React.FC = () => {
  const t = useTranslate();
  const { Link } = useRouterContext();
  const { organization: currentOrganization, setOrganization: setCurrentOrganization } = useContext(OrganizationContext);

  const { dataGridProps, tableQueryResult } = useDataGrid<IOrganization>();
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>(currentOrganization?.id ? [currentOrganization.id] : []);

  useEffect(() => {
    if (selectionModel[0]) {
      const selOrg = tableQueryResult.data?.data.find(org => org.id === selectionModel[0]);
      setCurrentOrganization(selOrg ? selOrg : null);
    }
  }, [selectionModel]);

  const columns = React.useMemo<GridColumns<IOrganization>>(
    () => [
      {
        field: "id",
        headerName: t("entity.id"),
        type: "number",
        width: 50,
      },
      {
        field: "shortName",
        headerName: t("organizations.shortName"),
        minWidth: 300,
        flex: 1,
      },
      {
        field: "fullName",
        headerName: t("organizations.fullName"),
        minWidth: 300,
        flex: 1,
      },
      {
        field: "actions",
        type: "actions",
        headerName: t("table.actions"),
        renderCell: function render({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <SimplePopper toggleName={t("common.more")}>
                <ButtonGroup size="small" aria-label="small button group" orientation="vertical">
                  <Button component={Link} to={`${row.id}/consumers`} size="small" variant="contained">{t("layout.menuHrefConsumers")}</Button>
                  <Button component={Link} to={`${row.id}/vouchers`} size="small" variant="contained">{t("layout.menuHrefVouchers")}</Button>
                  <Button component={Link} to={`${row.id}/deals`} size="small" variant="contained">{t("layout.menuHrefDeals")}</Button>
                  <Button component={Link} to={`${row.id}/reports/monthly`} size="small" variant="contained">{t("layout.menuHrefReports")}</Button>
                  <Button component={Link} to={`${row.id}/employees`} size="small" variant="contained">{t("layout.menuHrefEmployees")}</Button>
                  <Button component={Link} to={`${row.id}/services`} size="small" variant="contained">{t("layout.menuHrefServices")}</Button>
                </ButtonGroup>
              </SimplePopper>
              <ShowButton size="small" hideText recordItemId={row.id} />
              <EditButton size="small" hideText recordItemId={row.id} />
              <DeleteButton size="small" hideText recordItemId={row.id} />
            </Stack>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 200,
      },
    ],
    [t]
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        checkboxSelection={true}
        disableSelectionOnClick={false}
        onSelectionModelChange={(newSelectionModel) => {
          let difference = newSelectionModel.filter(x => !selectionModel.includes(x));
          setSelectionModel([difference[0]]);
        }}
        selectionModel={selectionModel}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
      />
    </List>
  );
};
