import {Controller, UseModalFormReturnType, useWatch} from "@pankod/refine-react-hook-form";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField, useAutocomplete,
} from "@pankod/refine-mui";
import {localToUTC, makeLocalAppearUTC} from "../../components/fields/dateTimePicker";
import dayjs from "dayjs";
import {DateTimePicker} from "@mui/x-date-pickers";
import React from "react";
import {useTranslate} from "@pankod/refine-core";
import {renderFullNameShort} from "../../utils/dataRenderUtils";
import {IConsumer, IDeal, IVoucher} from "../../interfaces";
import {IVoucherRouteParams} from "../vouchers";
import routerProvider from "@pankod/refine-react-router-v6";

const { useParams } = routerProvider;

export const VoucherActivationCreateModal: React.FC<UseModalFormReturnType & {voucher: IVoucher}> = ({
  register,
  control,
  formState: { errors },
  modal: { visible, close },
  saveButtonProps,
  voucher
}) => {
  const t = useTranslate();
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const params: IVoucherRouteParams = useParams();

  const consumer = useWatch({
    control,
    name: "consumer",
    defaultValue: null
  });

  const { autocompleteProps: autocompleteConsumerProps } = useAutocomplete<IConsumer>({
    resource: `organizations/${params.org_id}/consumers`,
    hasPagination: true,
    queryOptions: { enabled: visible },
    defaultValueQueryOptions: { enabled: false },
    debounce: 700,
    onSearch: (value) => [
      {
        field: "phone",
        operator: "contains",
        value,
      },
    ],
  });

  const { autocompleteProps: autocompleteDealProps } = useAutocomplete<IDeal>({
    resource: `organizations/${params.org_id}/consumers/${consumer}/deals`,
    hasPagination: true,
    queryOptions: { enabled: !!consumer },
    defaultValueQueryOptions: { enabled: false },
    debounce: 700,
  });

  return (
    <Dialog
      open={visible}
      onClose={close}
      maxWidth="xs"
      // fullScreen={fullScreen}
    >
      <DialogTitle>{t("voucherActivations.titles.create")}</DialogTitle>
      <DialogContent>
        <TextField
          {...register("valueUsed", {
            max: voucher.value - (voucher.valueUsed || 0),
            required: t("errors.required.field", { field: t("voucherActivations.valueUsed") }),
          })}
          error={!!errors.valueUsed}
          // @ts-ignore
          helperText={errors.valueUsed?.message}
          margin="normal"
          fullWidth
          label={t("voucherActivations.valueUsed")}
          required
          name="valueUsed"
          autoFocus
        />

        <Controller
          control={control}
          name="dateActivated"
          rules={{
            required: t("errors.required.field", { field: t("voucherActivations.dateActivated") }),
          }}
          // @ts-ignore
          defaultValue={localToUTC(dayjs((new Date()).valueOf()))}
          render={({ field }) => (
            <DateTimePicker
              {...field}
              label={t("voucherActivations.dateActivated")}
              value={makeLocalAppearUTC(field.value)}
              onChange={value => field.onChange({ target: { value: localToUTC(value) } })}
              renderInput={(params: any) => <TextField
                {...params}
                error={!!errors.dateActivated}
                helperText={errors.dateActivated?.message}
                required
                margin="normal"
              />}
            />
          )}
        />

        <Controller
          control={control}
          name="consumer"
          defaultValue={null as any}
          render={({ field }) => (
            <Autocomplete
              {...autocompleteConsumerProps}
              {...field}
              clearOnEscape
              noOptionsText={t('common.searchNoItems')}
              onChange={(_, value) => {
                field.onChange(value?.id || value);
              }}
              getOptionLabel={(item) => {
                const consumer = item.firstName
                  ? item
                  : autocompleteConsumerProps?.options?.find(
                    (p) =>
                      p.id.toString() ===
                      item.toString(),
                  );
                return renderFullNameShort(consumer) + ` (${consumer ? consumer.phone : item.phone})` ?? "";
              }}
              isOptionEqualToValue={(option, value: IConsumer|number) =>
                value === undefined || option.id.toString() === value.toString() || (typeof value === 'object' && option.id === value?.id)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("vouchers.consumer")}
                  margin="normal"
                  variant="outlined"
                  error={!!errors.consumer}
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name="deal"
          defaultValue={null as any}
          render={({ field }) => (
            <Autocomplete
              {...autocompleteDealProps}
              {...field}
              clearOnEscape
              noOptionsText={t('common.searchNoItems')}
              onChange={(_, value) => {
                field.onChange(value?.id || value);
              }}
              getOptionLabel={(item) => {
                return item.id ? item.comment
                  : autocompleteDealProps?.options?.find(
                    (p) => p.id.toString() === item.toString(),
                  )?.comment ?? "";
              }}
              isOptionEqualToValue={(option, value: IDeal|number) =>
                value === undefined || option.id.toString() === value.toString() || (typeof value === 'object' && option.id === value?.id)
              }
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label={t("voucherActivations.deal")}
                  margin="normal"
                  variant="outlined"
                  error={!!errors.deal}
                  helperText={errors.deal?.message}
                  required
                />
              )}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close()}>{t("buttons.cancel")}</Button>
        <Button {...saveButtonProps}>{t("common.save")}</Button>
      </DialogActions>
    </Dialog>
  );
};