import {IVoucherActivation} from "../../interfaces";
import {
  Card,
  CardHeader,
} from "@pankod/refine-mui";
import {DateField} from "../../components/fields/date";
import React from "react";

interface VoucherActivationCardProps {
  activation: IVoucherActivation;
}

export default function VoucherActivationCard(props: VoucherActivationCardProps) {
  let { activation } = props;

  return (
    <Card sx={{ minWidth: 250, maxWidth: 450 }}>
      <CardHeader
        title={activation.valueUsed}
        subheader={<DateField value={activation.dateActivated} format="LLL"/>}
      />
    </Card>
  );
}