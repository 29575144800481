import { useTranslate, HttpError } from "@pankod/refine-core";
import {
  Create,
  Box,
  TextField,
} from "@pankod/refine-mui";
import {useForm} from "@pankod/refine-react-hook-form";

import {IServiceType} from "interfaces";
import React from "react";
import {Typography} from "@mui/material";

export const ServiceTypeCreate: React.FC = () => {
  const t = useTranslate();

  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    formState: { errors },
  } = useForm<IServiceType, HttpError, IServiceType>();

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps} title={<Typography variant="h5">{t("organizationServices.titles.create")}</Typography>}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("title", {
            required: t("errors.required.field", { field: t("organizationServices.title") }),
          })}
          error={!!errors.title}
          helperText={errors.title?.message}
          margin="normal"
          fullWidth
          label={t("organizationServices.title")}
          name="title"
          autoFocus
        />
        <TextField
          {...register("description")}
          error={!!errors.description}
          helperText={errors.description?.message}
          margin="normal"
          fullWidth
          label={t("organizationServices.description")}
          name="description"
        />
        <TextField
          {...register("duration")}
          error={!!errors.duration}
          helperText={errors.duration?.message || t("organizationServices.durationHelperText")}
          margin="normal"
          fullWidth
          label={t("organizationServices.duration")}
          name="duration"
          type="number"
        />
        <TextField
          {...register("cost", {
            required: t("errors.required.field", { field: t("organizationServices.cost") }),
          })}
          error={!!errors.cost}
          helperText={errors.cost?.message}
          margin="normal"
          fullWidth
          label={t("organizationServices.cost")}
          name="cost"
        />
        <TextField
          {...register("currency", {
            required: t("errors.required.field", { field: t("organizationServices.currency") }),
          })}
          error={!!errors.currency}
          helperText={errors.currency?.message}
          margin="normal"
          fullWidth
          label={t("organizationServices.currency")}
          name="currency"
        />

      </Box>
    </Create>
  );
};
