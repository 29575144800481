import {Controller, UseModalFormReturnType} from "@pankod/refine-react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  MenuItem,
  TextField,
  // useMediaQuery,
  // useTheme
} from "@pankod/refine-mui";
import {localToUTC, makeLocalAppearUTC} from "../../components/fields/dateTimePicker";
import {DateTimePicker} from "@mui/x-date-pickers";
import React from "react";
import {usePermissions, useTranslate} from "@pankod/refine-core";
import {IConsumerRouteParams} from "../consumers";
import routerProvider from "@pankod/refine-react-router-v6";
import { PERM_EDIT_ACTION_IN_STATUS_DONE } from "constants/permissions";

const { useParams } = routerProvider;

export const ActionEditModal: React.FC<UseModalFormReturnType> = ({
  register,
  control,
  formState: { errors },
  refineCore: { onFinish, formLoading },
  // handleSubmit,
  modal: { visible, close },
  saveButtonProps,
}) => {
  const t = useTranslate();
  const params: IConsumerRouteParams = useParams();
  const { data: permissionsData } = usePermissions();
  let orgPermList = [];
  if (permissionsData && permissionsData[parseInt(params.org_id)]) {
    orgPermList = permissionsData[parseInt(params.org_id)];
  }
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open={visible}
      onClose={close}
      maxWidth="xs"
      // fullScreen={fullScreen}
    >
      {formLoading && <LinearProgress />}
      <DialogTitle>{t("customers.actions.titles.edit")}</DialogTitle>
      <DialogContent>
        <TextField
          {...register("description", {
            required: t("errors.required.field", { field: t("customers.actions.description") }),
          })}
          error={!!errors.description}
          // @ts-ignore
          helperText={errors.description?.message}
          margin="normal"
          fullWidth
          label={t("customers.actions.description")}
          required
          name="description"
          autoFocus
        />

        <TextField
          {...register("comment")}
          margin="normal"
          fullWidth
          label={t("customers.actions.comment")}
          multiline={true}
          maxRows={5}
          name="comment"
        />

        <Controller
          control={control}
          name="dateTime"
          rules={{
            required: t("errors.required.field", { field: t("customers.actions.dateTime") }),
          }}
          render={({ field }) => (
            <DateTimePicker
              {...field}
              label={t("customers.actions.dateTime")}
              value={makeLocalAppearUTC(field.value)}
              onChange={value => field.onChange({ target: { value: localToUTC(value) } })}
              renderInput={(params: any) => <TextField
                {...params}
                error={!!errors.dateTime}
                helperText={errors.dateTime?.message}
                required
                margin="normal"
              />}
            />
          )}
        />

        {orgPermList?.includes(PERM_EDIT_ACTION_IN_STATUS_DONE) &&
          <Controller
            control={control}
            name="status"
            rules={{
              required: t("errors.required.field", {field: t("customers.actions.status")}),
            }}
            defaultValue={0}
            render={({field}) => (
              <TextField
                {...field}
                margin="normal"
                fullWidth
                label={t("customers.actions.status")}
                select={true}
                required
                error={!!errors.status}
                // @ts-ignore
                helperText={errors.status?.message}
              >
                <MenuItem value={0}>{t('customers.actions.status_0')}</MenuItem>
                <MenuItem value={1}>{t('customers.actions.status_1')}</MenuItem>
                <MenuItem value={2}>{t('customers.actions.status_2')}</MenuItem>
              </TextField>
            )}
          />
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close()}>{t("buttons.cancel")}</Button>
        <Button {...saveButtonProps}>{t("common.save")}</Button>
      </DialogActions>
    </Dialog>
  );
};