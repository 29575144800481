import {IDeal, IServiceItem} from "../../interfaces";
import {useDelete, useInvalidate, usePermissions, useTranslate, useUpdate} from "@pankod/refine-core";
import {IConsumerRouteParams} from "../consumers";
import {useModalForm} from "@pankod/refine-react-hook-form";
import {BasicMenu, useBasicMenuState} from "../../components/navigation/basicMenu";
import React from "react";
import {
  Chip,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Switch,
  Tooltip,
  Typography
} from "@pankod/refine-mui";
import {PERM_EDIT_ACTION_IN_STATUS_DONE} from "../../constants/permissions";
import {DEAL_STATUS_CLOSED} from "../../constants/deal";
import {MoreVert, Person, Place, Sell, TimesOneMobiledata} from "@mui/icons-material";
import {ServiceItemEditModal} from "./editModal";
import {ConfirmDialog} from "../../components/feedback/confirmDialog";
import {ChipDateField} from "../../components/fields/date";
import routerProvider from "@pankod/refine-react-router-v6";
import {renderFullNameShort} from "../../utils/dataRenderUtils";

interface DealListItemProps {
  dealItem: IServiceItem;
  deal: IDeal;
  setOpenDealStatusConfirm: {(openState: boolean): any};
}

const { useParams } = routerProvider;

export const DealListItem = ({dealItem, deal, setOpenDealStatusConfirm}: DealListItemProps): JSX.Element => {
  const t = useTranslate();
  const params: IConsumerRouteParams = useParams();
  const { mutate: mutateServiceItem } = useUpdate<IServiceItem>();
  const { mutate: mutateDeleteServiceItem } = useDelete<IServiceItem>();
  const invalidate = useInvalidate();

  const editServiceItemModalFormReturnValues = useModalForm({
    refineCoreProps: {
      resource: `organizations/${params.org_id}/consumers/${params.id}/deals/${deal.id}/services`,
      action: "edit",
      redirect: false,
      onMutationSuccess: () => {
        invalidate({
          resource: `organizations/${params.org_id}/consumers`,
          invalidates: ["detail"],
          id: params.id,
        });
      },
    },
  });

  const {
    modal: { show: showEditModal },
  } = editServiceItemModalFormReturnValues;

  const moreVertMenuProps = useBasicMenuState();

  const [openItemDeleteConfirm, setOpenItemDeleteConfirm] = React.useState<boolean>(false);
  const handleCloseDeleteItemConfirm = () => {
    setOpenItemDeleteConfirm(false);
  };

  const { data: permissionsData } = usePermissions();
  let orgPermList = [];
  if (permissionsData && permissionsData[parseInt(params.org_id)]) {
    orgPermList = permissionsData[parseInt(params.org_id)];
  }

  function setExecuted(executed: boolean, item: IServiceItem) {
    item.executed = executed;
    mutateServiceItem({
      resource: `organizations/${params.org_id}/consumers/${params.id}/deals/${deal.id}/services`,
      id: item.id,
      values: { executed: executed },
    }, {
      onSuccess: (data) => {
        item = data.data;
        invalidate({
          resource: `organizations/${params.org_id}/consumers`,
          invalidates: ["detail"],
          id: params.id,
        });
        if (deal.serviceItems.every((i) => i.executed)) {
          setOpenDealStatusConfirm(true);
        }
      },
      onError: () => {
        invalidate({
          resource: `organizations/${params.org_id}/consumers`,
          invalidates: ["detail"],
          id: params.id,
        });
      }
    });
  }

  function deleteServiceItem() {
    mutateDeleteServiceItem({
      resource: `organizations/${params.org_id}/consumers/${params.id}/deals/${deal.id}/services`,
      id: dealItem.id,
    }, {
      onSuccess: () => {
        invalidate({
          resource: `organizations/${params.org_id}/consumers`,
          invalidates: ["detail"],
          id: params.id,
        });
      }
    })
  }

  const menuItems = [
    {text: t("common.edit"), onClick: () => {moreVertMenuProps.handleClose(); showEditModal(dealItem.id);}},
    {text: t("common.delete"), onClick: () => {moreVertMenuProps.handleClose(); setOpenItemDeleteConfirm(true)}},
  ];

  return (
    <ListItem disablePadding  secondaryAction={(orgPermList?.includes(PERM_EDIT_ACTION_IN_STATUS_DONE) || deal.status !== DEAL_STATUS_CLOSED) &&
    <BasicMenu {...moreVertMenuProps} id={"menu-" + dealItem.id} items={menuItems}>
        <IconButton edge="end" aria-label="more">
            <MoreVert />
        </IconButton>
    </BasicMenu>
    }>
      <ServiceItemEditModal {...editServiceItemModalFormReturnValues} />
      <ConfirmDialog
        open={openItemDeleteConfirm}
        dialogTitle={t("dealServices.titles.delete")}
        dialogContent={t("dealServices.deleteConfirm")}
        handleClose={handleCloseDeleteItemConfirm}
        handleAgree={() => {handleCloseDeleteItemConfirm(); deleteServiceItem();}}
      />
      <ListItemButton role={undefined} onClick={() => {}} dense>
        <ListItemIcon>
          <Tooltip title={t(dealItem.executed ? "dealServices.executed" : "dealServices.notExecuted")}>
            <Switch
              edge="start"
              checked={dealItem.executed}
              disabled={deal.status === DEAL_STATUS_CLOSED && !orgPermList?.includes(PERM_EDIT_ACTION_IN_STATUS_DONE)}
              onChange={(_, executed) => setExecuted(executed, dealItem)}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': `serviceItem${dealItem.id}` }}
            />
          </Tooltip>
        </ListItemIcon>
        <ListItemText
          id={`serviceItem${dealItem.id}`}
          primary={
            // <Tooltip> @TODO show additional info about serviceType
              dealItem.serviceType.title
            // </Tooltip>
          }
          secondary={
            <React.Fragment>
              <Typography component="span" variant="body2">{dealItem.comment}</Typography>
              <Stack spacing={1} direction="row" sx={{flexWrap: "wrap"}} component="span">
                <ChipDateField key={0} value={dealItem.executionDateTime} format="LLL" size="small" variantMapping={{body2: "span"}} />
                <Chip key={1} component="span" icon={<TimesOneMobiledata />} size="small" label={dealItem.amount} />
                <Chip key={2} component="span" icon={<Sell />} size="small" label={`${dealItem.customTotalPrice ?? 0} ${dealItem.serviceType.currency}`}/>
                {dealItem.executionPlace && <Chip key={3} component="span" icon={<Place />} size="small" label={dealItem.executionPlace}/>}
                {dealItem.executors && dealItem.executors.map((executor, index) => {
                  return <Chip key={index + 3} component="span" icon={<Person />} size="small" label={renderFullNameShort(executor)}/>
                })}
              </Stack>
            </React.Fragment>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}