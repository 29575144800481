import {AuthProvider} from "@pankod/refine-core";

import {TOKEN_KEY, API_URL, USER_STORE_KEY} from "./constants";
import { AxiosInstance } from "axios";

export const authProvider = (axiosInstance: AxiosInstance): AuthProvider => {
  return {
    login: async ({ username, email, password }) => {
      if ((username || email) && password) {
        try {
          const { data } = await axiosInstance.post(`${API_URL}/login`, {
            username: email,
            password: password,
          });

          localStorage.setItem(TOKEN_KEY, data.api_token);
          localStorage.setItem(USER_STORE_KEY, JSON.stringify(data));
        } catch (error) {
          return Promise.reject(error);
        }

        return Promise.resolve();
      }
      return Promise.reject(new Error("Credentials are empty"));
    },
    logout: () => {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(USER_STORE_KEY);
      return Promise.resolve();
    },
    register: async ({ email, password }) => {
      if (email && password) {
        try {
          await axiosInstance.post(`${API_URL}/users`, {
            username: email,
            email: email,
            password: password,
          });
        } catch (error) {
          return Promise.reject(error);
        }
        return Promise.resolve();
      }
      return Promise.reject(new Error("Credentials are empty"));
    },
    forgotPassword: async ({ email }) => {
      try {
        await axiosInstance.post(`${API_URL}/forgot-password`, {
          email: email,
        });
      } catch (error) {
        return Promise.reject(error);
      }
      return Promise.resolve();
    },
    checkError: (error) => {
      if (error.response.status === 401) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    checkAuth: async () => {
      return localStorage.getItem(TOKEN_KEY) ? Promise.resolve() : Promise.reject();
    },
    getPermissions: () => {
      const user = localStorage.getItem(USER_STORE_KEY);
      if (user) {
        const parsedUser = JSON.parse(user);
        return Promise.resolve(parsedUser.permissions);
      }
      return Promise.reject();
    },
    getUserIdentity: async () => {
      const user = localStorage.getItem(USER_STORE_KEY);
      if (user) {
        const parsedUser = JSON.parse(user);
        return Promise.resolve(parsedUser);
      }
      return Promise.reject();
    },
  }
};
