import routerProvider from "@pankod/refine-react-router-v6";
import React, {useEffect} from "react";
import {useTranslate} from "@pankod/refine-core";
import {
  Checkbox,
  DataGrid,
  DeleteButton,
  Divider,
  EditButton,
  FormControlLabel,
  getGridNumericOperators,
  getGridStringOperators,
  Grid,
  GridColumns,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  ruRU,
  ShowButton,
  Stack,
  useDataGrid
} from "@pankod/refine-mui";
import {IVoucher} from "../../interfaces";
import {renderName} from "../../utils/dataRenderUtils";
import {List} from "../../components/crud/list";
import {IVoucherRouteParams} from "./index";
import { DateField } from "components/fields/date";

const { useParams } = routerProvider;

export const VoucherList: React.FC = () => {
  const t = useTranslate();
  const [filterExpired, setFilterExpired] = React.useState(true);

  const params: IVoucherRouteParams = useParams();
  const {dataGridProps, search, setFilters, filters} = useDataGrid<IVoucher>({
    resource: `organizations/${params.org_id ?? 0}/vouchers`,
    defaultSetFilterBehavior: "merge",
    initialSorter: [{
      field: "expirationDate",
      order: "asc"
    }],
    initialFilter: [{
      field: "filterExpired",
      operator: "eq",
      value: filterExpired,
    }],
    onSearch: (data: any) => {
      if (data?.q) {
        return [{
          field: "code",
          operator: "eq",
          value: data.q
        }];
      }
      return [];
    }
    // syncWithLocation: true,
  });

  const stringColumnFilterOperations = {
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === 'contains',
    )
  };

  const numberColumnFilterOperations = {
    filterOperators: getGridNumericOperators().filter(
      (operator) => operator.value === '=',
    )
  };

  useEffect(() => {
    setFilters([{
      field: "filterExpired",
      operator: "eq",
      value: filterExpired,
    }]);
  }, [filterExpired]);

  const columns = React.useMemo<GridColumns<IVoucher>>(
    () => [
      {
        ...numberColumnFilterOperations,
        field: "id",
        headerName: t("entity.id"),
        type: "number",
        width: 50,
        hide: true,
        filterable: false
      },
      {
        field: "code",
        headerName: t("vouchers.code"),
        minWidth: 100,
        flex: 1,
        hideable: false,
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === 'equals',
        )
      },
      {
        ...numberColumnFilterOperations,
        field: "valueLeft",
        headerName: t("vouchers.valueLeft"),
        minWidth: 100,
        flex: 1,
        filterable: false,
        valueGetter: params => `${params.row.value - params.row.valueUsed} / ${params.row.value} ${params.row.type.currency}`,
      },
      {
        field: "expirationDate",
        headerName: t("vouchers.expirationDate"),
        minWidth: 100,
        flex: 1,
        filterable: false,
        renderCell: function render(params) {
          return (
            <DateField format="LL" value={params.row.expirationDate} />
          );
        },
      },
      {
        field: "type",
        headerName: t("vouchers.type"),
        minWidth: 100,
        flex: 1,
        filterable: false,
        valueGetter: params => params.row.type.title,
      },
      {
        field: "consumer",
        headerName: t("vouchers.consumer"),
        minWidth: 100,
        flex: 1,
        filterable: false,
        valueGetter: params => renderName(params.row.consumer),
      },
      {
        field: "comment",
        headerName: t("vouchers.comment"),
        minWidth: 100,
        flex: 1,
        filterable: false,
      },
      {
        field: "tactions",
        type: "actions",
        headerName: t("table.actions"),
        renderCell: function render({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <ShowButton size="small" hideText recordItemId={row.id} />
              <EditButton size="small" disabled hideText recordItemId={row.id} resourceNameOrRouteName={`organizations/${params.org_id}/vouchers`} />
              <DeleteButton size="small" disabled hideText recordItemId={row.id} resourceNameOrRouteName={`organizations/${params.org_id}/vouchers`} />
            </Stack>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 100,
      },
    ],
    [t]
  );

  return (
    <List
      title={t("vouchers.titles.list")}
      canCreate={true}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 60}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        onFilterModelChange={(model, details) => {
          if (model.items.length && model.items[0]?.value === undefined) {
            // @TODO This is a rude patch for condition while grid filter is removed (value undefined) and filter behaviour is merge.
            // Without this fix grid would not update while removing filter from its column
            setFilters([{
              field: model.items[0].columnField,
              operator: 'eq',
              value: model.items[0].value,
            }]);
          }
          return dataGridProps.onFilterModelChange(model, details);
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500, onChange: (_: any, value: string) => search(value)},
            filterExpired: filterExpired,
            setFilterExpired: setFilterExpired,
          },
        }}
        components={{
          Toolbar: CustomGridToolbar,
          //     Toolbar: GridToolbar,
        }}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
        }}
      />
    </List>
  );
}


const CustomGridToolbar = ({filterExpired, setFilterExpired}: {
  filterExpired: boolean,
  setFilterExpired: (expired: boolean) => void,
}): JSX.Element => {
  const t = useTranslate();

  return (
    <GridToolbarContainer>
      <Grid container>
        <Grid item sm={4}>
          <GridToolbarQuickFilter />
          <GridToolbarFilterButton />
          <GridToolbarColumnsButton />
        </Grid>
        <Divider orientation="vertical" variant="middle" flexItem light/>
        <Grid item sm marginLeft={5}>
          <FormControlLabel control={
            <Checkbox value={filterExpired} defaultChecked={filterExpired} onChange={(_, value) => setFilterExpired(value)} />
          } label={t('vouchers.hideExpired')} />
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}