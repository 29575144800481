import {IConsumer} from "../../interfaces";
import {useShow, useTranslate} from "@pankod/refine-core";
import {
  Grid,
  Show,
  Stack,
  Typography
} from "@pankod/refine-mui";
import routerProvider from "@pankod/refine-react-router-v6";
import React from "react";
import {renderFullNameShort, renderName} from "utils/dataRenderUtils";
import { DateField } from "components/fields/date";
import { IConsumerRouteParams } from ".";
import { ConsumerShowTabs } from "./showTabs";

const { useParams, Link } = routerProvider;

export const ConsumerShow: React.FC = () => {
  const { queryResult } = useShow<IConsumer>();
  const t = useTranslate();

  const params: IConsumerRouteParams = useParams();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show
      isLoading={isLoading}
      title={<Typography variant="h5">{t("consumers.titles.show")}</Typography>} // @TODO this and next props should not be used, this data comes form resource
      canDelete={true}
      canEdit={true}
    >
      <Grid container spacing={2}>
        <Grid xs={12} sm={3} md={2} item={true}>
          <Stack gap={1}>
            <Typography variant="body1" fontWeight="bold">{t("customers.name")}</Typography>
            <Typography variant="body2">{record ? renderName(record) : ''}</Typography>

            <Typography variant="body1" fontWeight="bold">{t("customers.phone")}</Typography>
            <Typography variant="body2">{record?.phone}</Typography>

            <Typography variant="body1" fontWeight="bold">{t("customers.email")}</Typography>
            <Typography variant="body2">{record?.email}</Typography>

            <Typography variant="body1" fontWeight="bold">{t("customers.comment")}</Typography>
            <Typography variant="body2">{record?.comment}</Typography>

            <Typography variant="body1" fontWeight="bold">{t("consumers.conversionDateTime")}</Typography>
            <DateField value={record?.conversionDateTime} format="LLL"/>

            <Typography variant="body1" fontWeight="bold">{t("customers.source")}</Typography>
            <Typography variant="body2">{record?.source?.visibleName}</Typography>

            <Typography variant="body1" fontWeight="bold">{t("customers.category")}</Typography>
            <Typography variant="body2">{record?.category?.visibleName}</Typography>

            <Typography variant="body1" fontWeight="bold">{t("customers.manager")}</Typography>
            <Typography variant="body2">
              {record?.manager && <Link to={`/organizations/${params.org_id}/employees/show/${record.manager.id}`}>{renderFullNameShort(record?.manager)}</Link>}
            </Typography>

            <Typography variant="body1" fontWeight="bold">{t("customers.birthDate")}</Typography>
            <DateField value={record?.birthDate}/>

            <Typography variant="body1" fontWeight="bold">{t("customers.gender")}</Typography>
            <Typography variant="body2">{record?.gender ? t(`entity.gender_${record?.gender}`) : ''}</Typography>

            <Typography variant="body1" fontWeight="bold">{t("customers.city")}</Typography>
            <Typography variant="body2">{record?.city}</Typography>

            <Typography variant="body1" fontWeight="bold">{t("customers.status")}</Typography>
            <Typography variant="body2">{t(`consumers.status_${record?.status}`)}</Typography>
          </Stack>
        </Grid>
        <Grid xs={12} sm={9} md={10} item={true}>
          <ConsumerShowTabs consumer={record}/>
        </Grid>
      </Grid>
    </Show>
  );
};
