import React from "react";
import {useGetLocale, useTranslate} from "@pankod/refine-core";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  Stack,
  EditButton,
  DeleteButton,
  Chip, getGridNumericOperators, getGridStringOperators, ruRU, ShowButton, Badge,
} from "@pankod/refine-mui";
import routerProvider from "@pankod/refine-react-router-v6";
import {IConsumer, ICustomerAction, IDeal} from "interfaces";
import {renderDateTime, renderName} from "../../utils/dataRenderUtils";
import CustomGridToolbar from "../../components/cutomGridToolbar";
import { List } from "components/crud/list";
import { IConsumerRouteParams } from ".";
import { DateField } from "components/fields/date";
import { ShoppingCart as ShoppingCartIcon } from "@mui/icons-material";

const { useParams } = routerProvider;

export const ConsumerList: React.FC = () => {
  const t = useTranslate();
  const l = useGetLocale();

  const params: IConsumerRouteParams = useParams();
  const {dataGridProps} = useDataGrid<IConsumer>({
    resource: `organizations/${params.org_id ?? 0}/consumers`,
    initialSorter: [{
        field: "id",
        order: "desc"
    }],
    // syncWithLocation: true,
  });

  const stringColumnFilterOperations = {
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === 'contains',
    )
  };

  const numberColumnFilterOperations = {
    filterOperators: getGridNumericOperators().filter(
      (operator) => operator.value === '=',
    )
  };

  const columns = React.useMemo<GridColumns<IConsumer>>(
    () => [
      {
        ...numberColumnFilterOperations,
        field: "id",
        headerName: t("entity.id"),
        type: "number",
        width: 65,
        hideable: false,
      },
      {
        ...stringColumnFilterOperations,
        field: "name",
        headerName: t("customers.name"),
        minWidth: 150,
        flex: 1,
        hideable: false,
        valueGetter: params => renderName(params.row),
      },
      {
        ...stringColumnFilterOperations,
        field: "phone",
        headerName: t("customers.phone"),
        minWidth: 110,
        maxWidth: 150,
        flex: 1,
        hideable: false,
      },
      {
        ...stringColumnFilterOperations,
        field: "email",
        headerName: t("customers.email"),
        minWidth: 200,
        flex: 1,
        hide: true,
      },
      {
        ...stringColumnFilterOperations,
        field: "comment",
        headerName: t("customers.comment"),
        minWidth: 200,
        flex: 1,
      },
      {
        field: "conversionDateTime",
        headerName: t("consumers.conversionDateTime"),
        type: 'dateTime',
        minWidth: 105,
        maxWidth: 145,
        flex: 1,
        renderCell: function render({ value }) {
          return <DateField value={value} format="DD.MM.YY HH:mm" />;
        },
      },
      {
        field: "deals",
        headerName: t("consumers.deals"),
        maxWidth: 90,
        flex: 1,
        renderCell: function render({ row }) {
          return <Badge badgeContent={row.deals.length} color="primary">
            <ShoppingCartIcon />
          </Badge >
          // return <Stack spacing={1}>
          //   {row.deals.map(function(item: IDeal) {
          //     return <Chip key={item.id} label={`${item.comment}: ${item.serviceItems.length}`} sx={{maxWidth: 250}} variant="outlined" color="info" size="small" />;
          //   })}
          // </Stack>
        },
      },
      {
        field: "actions",
        headerName: t("customers.pendingActions"),
        maxWidth: 220,
        minWidth: 220,
        flex: 1,
        renderCell: function render({ row }) {
          return <Stack spacing={1}>
            {row.actions.map(function(item: ICustomerAction) {
              return <Chip key={item.id} label={
                <>{renderDateTime(item.dateTime, l(), "DD.MM.YY HH:mm")} - {item.description}</>
              } sx={{maxWidth: 200}} variant="outlined" color="info" size="small" />;
            })}
          </Stack>
        },
      },
      {
        field: "tactions",
        type: "actions",
        headerName: t("table.actions"),
        renderCell: function render({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <ShowButton size="small" hideText recordItemId={row.id} />
              <EditButton size="small" hideText recordItemId={row.id} resourceNameOrRouteName={`organizations/${params.org_id}/consumers`} />
              <DeleteButton size="small" hideText recordItemId={row.id} resourceNameOrRouteName={`organizations/${params.org_id}/consumers`} />
            </Stack>
        );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 100,
      },
    ],
    [t]
  );

  return (
    <List
      title={t("consumers.titles.list")}
      canCreate={true}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 60}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Toolbar: CustomGridToolbar,
        }}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
        }}
      />
    </List>
  );
}