import {IVoucher} from "../../interfaces";
import {useInvalidate, useTranslate} from "@pankod/refine-core";
import React from "react";
import {IVoucherRouteParams} from "./index";
import {useModalForm} from "@pankod/refine-react-hook-form";
import {Box, Fab, Grid, Tab, Tabs, Tooltip} from "@pankod/refine-mui";
import {a11yProps, TabPanel} from "../../components/layout/tabs";
import {Add} from "@mui/icons-material";
import routerProvider from "@pankod/refine-react-router-v6";
import VoucherActivationCard from "pages/vouchersActivations/activationCard";
import {VoucherActivationCreateModal} from "../vouchersActivations/createModal";
import {createErrorNotification} from "../../utils/errorNotification";

const { useParams } = routerProvider;

interface BasicTabsProps {
  voucher?: IVoucher;
}

export const VoucherShowSide: React.FC<BasicTabsProps> = ({voucher}) => {
  const t = useTranslate();
  const [tab, setTab] = React.useState(0);
  const params: IVoucherRouteParams = useParams();
  const invalidate = useInvalidate();

  const createActivationModalFormReturnValues = useModalForm({
    refineCoreProps: {
      resource: `organizations/${params.org_id}/vouchers/${params.id}/activations`,
      action: "create",
      redirect: false,
      onMutationSuccess: () => {
        invalidate({
          resource: `organizations/${params.org_id}/vouchers`,
          invalidates: ["detail"],
          id: params.id,
        });
      },
      ...createErrorNotification(t, "voucherActivations.addNew")
    },
  });

  if (!voucher) {
    return <></>;
  }

  const handleChangeTab = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const {
    modal: { show: showCreateActivationModal },
  } = createActivationModalFormReturnValues;

  let activations = [...voucher.activations];
  activations.sort((a, b) => {
    return (new Date(a.dateActivated)).valueOf() - (new Date(b.dateActivated)).valueOf();
  });

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab label={t("vouchers.activations")} {...a11yProps(0)} />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <Tooltip title={t("voucherActivations.addNew")}>
          <Fab disabled={voucher.expired} color="success" aria-label="add" size="large" onClick={() => showCreateActivationModal()} sx={{ position: 'fixed', bottom: 16, right: 16 }}>
            <Add />
          </Fab>
        </Tooltip>
        <VoucherActivationCreateModal {...createActivationModalFormReturnValues} voucher={voucher} />
        <Grid container direction="row" columns={3} spacing={2}>
          {activations.map(value => {
            return <Grid key={value.id} item><VoucherActivationCard activation={value} /></Grid>
          })}
        </Grid>
      </TabPanel>
    </Box>
  );
}