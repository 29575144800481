import { useTranslate, HttpError } from "@pankod/refine-core";
import {
  Box,
  TextField,
  Autocomplete,
  useAutocomplete,
  InputAdornment, MenuItem,
} from "@pankod/refine-mui";
import {Controller, useForm, useWatch} from "@pankod/refine-react-hook-form";
import routerProvider from "@pankod/refine-react-router-v6";
import {IConsumer, IVoucher, IVoucherType} from "interfaces";
import React from "react";
import {Typography} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers";
import {localToUTC, makeLocalAppearUTC} from "../../components/fields/dateTimePicker";
import {renderFullNameShort} from "../../utils/dataRenderUtils";
import { Create } from "components/crud/create";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { IVoucherRouteParams } from ".";
import {createErrorNotification} from "../../utils/errorNotification";

dayjs.extend(duration);

const { useParams } = routerProvider;

export const VoucherCreate: React.FC = () => {
  const t = useTranslate();
  const params: IVoucherRouteParams = useParams();

  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    control,
    formState: { errors },
  } = useForm<IVoucher, HttpError, IVoucher & { manager: IVoucherType }>({
    refineCoreProps: {
      ...createErrorNotification(t, "voucher.titles.create"),
    }
  });

  // @TODO useSelect
  const { autocompleteProps: autocompleteVoucherTypeProps } = useAutocomplete<IVoucherType>({
    resource: `organizations/${params.org_id}/voucher-types`,
    hasPagination: true,
    queryOptions: { enabled: true },
    defaultValueQueryOptions: { enabled: false },
    debounce: 700,
    filters: [
      {
        field: "archive",
        operator: "eq",
        value: false,
      },
    ],
  });

  const { autocompleteProps: autocompleteConsumerProps } = useAutocomplete<IConsumer>({
    resource: `organizations/${params.org_id}/consumers`,
    hasPagination: true,
    queryOptions: { enabled: true },
    defaultValueQueryOptions: { enabled: false },
    debounce: 700,
    onSearch: (value) => [
      {
        field: "phone",
        operator: "contains",
        value,
      },
    ],
  });

  // @ts-ignore
  const voucherTypeId: number = useWatch({
    control,
    name: "type",
    defaultValue: undefined
  });

  const dateAcquiredValue = useWatch({
    control,
    name: "dateAcquired",
  });

  let voucherType: IVoucherType|undefined = undefined;
  if (voucherTypeId) {
    voucherType = autocompleteVoucherTypeProps.options.filter((i) => i.id === voucherTypeId)[0];
  }

  let valueValidationOptions = {
    required: t("errors.required.field", { field: t("vouchers.value") }),
  };

  if (voucherType?.valueMin) {
    Object.assign(valueValidationOptions, {
      min: {
        value: Number.parseFloat(voucherType?.valueMin),
        message: t("vouchers.validation.valueMin", {valueMin: voucherType?.valueMin})
      }
    });
  } else {
    Object.assign(valueValidationOptions, {
      min: {}
    });
  }

  if (voucherType?.valueMax) {
    Object.assign(valueValidationOptions, {
      max: {
        value: Number.parseFloat(voucherType?.valueMax),
        message: t("vouchers.validation.valueMax", {valueMax: voucherType?.valueMax})
      }
    });
  } else {
    Object.assign(valueValidationOptions, {
      max: {}
    });
  }

  return (
    <Create
      title={<Typography variant="h5">{t("vouchers.titles.create")}</Typography>}
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("code")}
          margin="normal"
          fullWidth
          label={t("vouchers.code")}
          name="code"
          placeholder={t("vouchers.fields.codePlaceholder")}
        />

        {/* @TODO solve changing controlled state issue*/}
        <TextField
          {...register("value", valueValidationOptions)}
          error={!!errors.value}
          helperText={errors.value?.message}
          margin="normal"
          select={!!voucherType?.possibleValues}
          fullWidth
          label={t("vouchers.value")}
          name="value"
          autoFocus
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">{voucherType?.currency}</InputAdornment>,
          }}
        >
          {voucherType?.possibleValues?.map((item: string, index: number) => {
            return <MenuItem key={index} value={item}>{item}</MenuItem>
          })}
        </TextField>

        <Controller
          control={control}
          name="dateAcquired"
          // @ts-ignore
          defaultValue={localToUTC(dayjs((new Date()).valueOf()))}
          render={({ field }) => (
            <DateTimePicker
              {...field}
              label={t("vouchers.dateAcquired")}
              value={makeLocalAppearUTC(field.value)}
              onChange={value => field.onChange({ target: { value: localToUTC(value) } })}
              renderInput={(params: any) => <TextField {...params} margin="normal" />}
            />
          )}
        />

        {voucherType?.expirationPeriod &&
          <TextField
            label={t("vouchers.expirationDate")}
            value={dayjs(dateAcquiredValue).add(dayjs.duration(voucherType?.expirationPeriod)).format('LL')}
            disabled
            margin="normal"
          />
        }

        <TextField
          {...register("comment")}
          margin="normal"
          fullWidth
          label={t("vouchers.comment")}
          name="comment"
        />

        <Controller
          control={control}
          name="type"
          defaultValue={null as any}
          render={({ field }) => (
            <Autocomplete
              {...autocompleteVoucherTypeProps}
              {...field}
              clearOnEscape
              noOptionsText={t('common.searchNoItems')}
              onChange={(_, value) => {
                field.onChange(value?.id || value);
              }}
              getOptionLabel={(item) => {
                return item.title ? `${item.title}` : autocompleteVoucherTypeProps?.options?.find(
                    (p) => p.id.toString() === item.toString(),
                )?.title ?? "";
              }}
              isOptionEqualToValue={(option, value: IVoucherType|number) =>
                  value === undefined || option.id.toString() === value.toString() || (typeof value === 'object' && option.id === value?.id)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("vouchers.type")}
                  margin="normal"
                  variant="outlined"
                  error={!!errors.type}
                  helperText={errors.type?.message}
                  required
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name="consumer"
          defaultValue={null as any}
          render={({ field }) => (
            <Autocomplete
              {...autocompleteConsumerProps}
              {...field}
              clearOnEscape
              noOptionsText={t('common.searchNoItems')}
              onChange={(_, value) => {
                field.onChange(value?.id || value);
              }}
              getOptionLabel={(item) => {
                const consumer = item.firstName
                  ? item
                  : autocompleteConsumerProps?.options?.find(
                    (p) =>
                      p.id.toString() ===
                      item.toString(),
                  );
                return renderFullNameShort(consumer) + ` (${consumer ? consumer.phone : item.phone})` ?? "";
              }}
              isOptionEqualToValue={(option, value: IConsumer|number) =>
                value === undefined || option.id.toString() === value.toString() || (typeof value === 'object' && option.id === value?.id)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("vouchers.consumer")}
                  margin="normal"
                  variant="outlined"
                  error={!!errors.consumer}
                  helperText={errors.consumer?.message}
                />
              )}
            />
          )}
        />
      </Box>
    </Create>
  );
};
