import React from "react";

import {Refine, usePermissions} from "@pankod/refine-core";
import {
  notificationProvider,
  RefineSnackbarProvider,
  CssBaseline,
  GlobalStyles,
  ReadyPage,
  ErrorComponent,
  AuthPage,
} from "@pankod/refine-mui";

import axios, { AxiosRequestConfig } from "axios";
import  {BrowserRouterComponent} from "@pankod/refine-react-router-v6";
import { useTranslation } from "react-i18next";
import { Title, Sider, Layout, Header } from "components/layout";
import { ColorModeContextProvider } from "contexts";
import { OrganizationList, OrganizationShow, OrganizationCreate, OrganizationEdit } from "pages/organizations";
import { ConsumerList, ConsumerShow, ConsumerCreate, ConsumerEdit } from "pages/consumers";
import {EmployeeCreate, EmployeeEdit, EmployeeList, EmployeeShow} from "pages/employees";
import { authProvider } from "./authProvider";
import { TOKEN_KEY, API_URL } from "./constants";
import { routerProvider } from "./routerProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/ru' // import locale
import dayjs, { Dayjs } from 'dayjs';
import {FinanceOperationsReport, MonthlyReportList, CustomersStatisticsReport} from "pages/reports";
import { dataProvider } from "rest-data-provider";
import { VoucherCreate, VoucherList, VoucherShow } from "./pages/vouchers";
import {DealsList} from "./pages/deals";
import {PERM_FINANCE_ACCOUNTS, PERM_ORGANIZATION_EMPLOYEE_MANAGE} from "./constants/permissions";
import {ServiceTypeCreate, ServiceTypeEdit, ServiceTypeList, ServiceTypeShow} from "pages/serviceTypes";
import {FinanceAccountCreate, FinanceAccountEdit, FinanceAccountList, FinanceAccountShow} from "./pages/financeAccounts";
import {
  FinanceOperationCreate,
  FinanceOperationEdit,
  FinanceOperationList,
  FinanceOperationShow
} from "./pages/financeOperations";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
        return request;
    }
    // Check if the header property exists
    if (request.headers) {
        // Set the Authorization header if it exists
        request.headers["x-auth-token"] = `${token}`;
    } else {
        // Create the headers property if it does not exist
        request.headers = {
            "x-auth-token": `${token}`,
        };
    }

    return request;
});

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  // const { data: permissionsData } = usePermissions();
  // let orgPermList = [];
  // if (permissionsData && permissionsData[parseInt(params.org_id)]) {
  //   orgPermList = permissionsData[parseInt(params.org_id)];
  // }

  dayjs.locale(i18n.language);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
      <ColorModeContextProvider>
        <CssBaseline />
        <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
        <RefineSnackbarProvider>
          <Refine
            dataProvider={dataProvider(API_URL, axiosInstance)}
            notificationProvider={notificationProvider}
            ReadyPage={ReadyPage}
            catchAll={<ErrorComponent />}
            Title={Title}
            Sider={Sider}
            Layout={Layout}
            Header={Header}
            authProvider={authProvider(axiosInstance)}
            LoginPage={AuthPage}
            i18nProvider={i18nProvider}
            // liveProvider={}
            options={{
              // breadcrumb:
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
              // liveMode: "auto",
              disableTelemetry: true,
              redirect: {
                afterCreate: "show",
                afterClone: "edit",
                afterEdit: false,
              },
            }}
            routerProvider={{
                ...routerProvider,
                routes: [
                  { path: "/login", element: <AuthPage/> },
                  { path: "/register", element: <AuthPage type="register" /> },
                  { path: "/forgot-password", element: <AuthPage type="forgotPassword" /> },
                  // {
                  //   element: <ConsumerList />,
                  //   path: "/organizations/:org_id/consumers",
                  //   layout: true,
                  // },
                  // {
                  //   element: <ConsumerEdit />,
                  //   path: "/organizations/:org_id/consumers/edit/:id",
                  //   layout: true,
                  // },
                  // {
                  //   element: <ConsumerShow />,
                  //   path: "/organizations/consumers/show/:id",
                  //   layout: true,
                  // },
                ],
                RouterComponent: BrowserRouterComponent,
            }}
            resources={[
              {
                name: "organizations",
                options: {
                  label: t("layout.menuHrefOrganizations"),
                },
                list: OrganizationList,
                create: OrganizationCreate,
                edit: OrganizationEdit,
                show: OrganizationShow,
                canDelete: true,
              },
              {
                name: "organizations/consumers",
                options: {
                  route: "organizations/:org_id/consumers",
                  label: t("layout.menuHrefConsumers"),
                  // dataProviderName: '',
                },
                list: ConsumerList,
                create: ConsumerCreate,
                edit: ConsumerEdit,
                show: ConsumerShow,
                // parentName: 'organizations',
                canDelete: true,
              },
              {
                name: "organizations/vouchers",
                options: {
                  route: "organizations/:org_id/vouchers",
                  label: t("layout.menuHrefVouchers"),
                },
                list: VoucherList,
                create: VoucherCreate,
                // edit: VoucherEdit,
                show: VoucherShow,
                canDelete: true,
              },
              {
                name: "organizations/reports",
                options: {
                  route: "organizations/:org_id/reports/monthly",
                  label: t("layout.menuHrefReports"),
                  // dataProviderName: '',
                },
                list: MonthlyReportList,
              },
              {
                name: "organizations/consumers/actions",
                options: {
                  route: "organizations/:org_id/consumers/:consumer_id/actions",
                  label: t("layout.menuHrefActions"),
                },
                // list: ,
                // create: ,
                // edit: ,
                canDelete: true,
              },
              {
                name: "organizations/consumers/deals",
                options: {
                  route: "organizations/:org_id/consumers/:consumer_id/deals",
                  label: t("layout.menuHrefDeals"),
                },
                // list: DealsList,
                // create: ,
                // edit: ,
                canDelete: true,
              },
              {
                name: "organizations/deals",
                options: {
                  route: "organizations/:org_id/deals",
                  label: t("layout.menuHrefDeals"),
                },
                list: DealsList,
                // create: ,
                // edit: ,
                canDelete: true,
              },
              {
                name: "organizations/employees",
                options: {
                  route: "organizations/:org_id/employees",
                  label: t("layout.menuHrefEmployees"),
                  permission: PERM_ORGANIZATION_EMPLOYEE_MANAGE,
                },
                list: EmployeeList,
                create: EmployeeCreate,
                edit: EmployeeEdit,
                show: EmployeeShow,
                canDelete: true,
              },
              {
                name: "organizations/services",
                options: {
                  route: "organizations/:org_id/services",
                  label: t("layout.menuHrefServices"),
                },
                list: ServiceTypeList,
                create: ServiceTypeCreate,
                edit: ServiceTypeEdit,
                show: ServiceTypeShow,
                canDelete: false,
              },
              {
                name: "organizations/accounts",
                options: {
                  route: "organizations/:org_id/accounts",
                  label: t("layout.menuHrefFinanceAccounts"),
                  permission: PERM_FINANCE_ACCOUNTS,
                },
                list: FinanceAccountList,
                create: FinanceAccountCreate,
                edit: FinanceAccountEdit,
                show: FinanceAccountShow,
                canDelete: false,
              },
              {
                name: "organizations/accounts/operations",
                options: {
                  route: "organizations/:org_id/accounts/:account_id/operations",
                  label: t("layout.menuHrefFinanceOperations"),
                  permission: PERM_FINANCE_ACCOUNTS,
                },
                list: FinanceOperationList,
                create: FinanceOperationCreate,
                edit: FinanceOperationEdit,
                show: FinanceOperationShow,
                canDelete: true,
              },
              {
                name: "organizations/reports/finance",
                options: {
                  route: "organizations/:org_id/reports/finance-operations",
                  label: t("layout.menuHrefFinanceReports"),
                  permission: PERM_FINANCE_ACCOUNTS,
                },
                list: FinanceOperationsReport,
              },
              {
                name: "organizations/reports/customers",
                options: {
                  route: "organizations/:org_id/reports/customers-statistics",
                  label: t("layout.menuHrefCustomersStatistics"),
                  // permission: PERM_FINANCE_ACCOUNTS,
                },
                list: CustomersStatisticsReport,
              },
            ]}
          />
        </RefineSnackbarProvider>
      </ColorModeContextProvider>
    </LocalizationProvider>
  );
}

export default App;
