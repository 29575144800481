import {IEmployee} from "../../interfaces";
import {useShow, useTranslate} from "@pankod/refine-core";
import {
  Stack,
  Typography
} from "@pankod/refine-mui";
import { Show } from "components/crud/show";
import {renderFullNameLong} from "../../utils/dataRenderUtils";
import React from "react";

export const EmployeeShow: React.FC = () => {
  const { queryResult } = useShow<IEmployee>();
  const t = useTranslate();

  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}
          title={<Typography variant="h5">{t("employees.titles.show")}</Typography>} // @TODO this and next props should not be used, this data comes form resource
    >
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">{t("employees.name")}</Typography>
        <Typography variant="body2">{record ? renderFullNameLong(record) : ''}</Typography>
        <Typography variant="body1" fontWeight="bold">{t("employees.permissions")}</Typography>
        <Stack>
          {record?.permissions.map(value => {
            return <React.Fragment>
              <li key={value}>{t(`employees.${value}`)}</li>
            </React.Fragment>
          })}
        </Stack>
      </Stack>
    </Show>
  );
};