import {IVoucher} from "../../interfaces";
import {useShow, useTranslate} from "@pankod/refine-core";
import {
  Grid,
  Show,
  Stack,
  Typography
} from "@pankod/refine-mui";
import routerProvider from "@pankod/refine-react-router-v6";
import React from "react";
import {renderFullNameShort} from "utils/dataRenderUtils";
import { DateField } from "components/fields/date";
import { IVoucherRouteParams } from ".";
import {VoucherShowSide} from "./showSide";

const { useParams, Link } = routerProvider;

export const VoucherShow: React.FC = () => {
  const { queryResult } = useShow<IVoucher>();
  const t = useTranslate();

  const params: IVoucherRouteParams = useParams();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show
      isLoading={isLoading}
      title={<Typography variant="h5">{t("vouchers.titles.show")}</Typography>} // @TODO this and next props should not be used, this data comes form resource
      canDelete={true}
      canEdit={true}
    >
      <Grid container spacing={2}>
        <Grid xs={12} sm={3} md={2} item={true}>
          <Stack gap={1}>
            <Typography variant="body1" fontWeight="bold">{t("vouchers.code")}</Typography>
            <Typography variant="body2">{record?.code}</Typography>

            <Typography variant="body1" fontWeight="bold">{t("vouchers.value")}</Typography>
            <Typography variant="body2">{record?.value} {record?.type.currency}</Typography>

            <Typography variant="body1" fontWeight="bold">{t("vouchers.valueUsed")}</Typography>
            <Typography variant="body2">{record?.valueUsed} {record?.type.currency}</Typography>

            <Typography variant="body1" fontWeight="bold">{t("vouchers.type")}</Typography>
            <Typography variant="body2">{record?.type.title}</Typography>

            <Typography variant="body1" fontWeight="bold">{t("vouchers.dateAcquired")}</Typography>
            <DateField value={record?.dateAcquired} format="LL"/>

            <Typography variant="body1" fontWeight="bold">{t("vouchers.expirationDate")}</Typography>
            <DateField value={record?.expirationDate} format="LLL"/>

            <Typography variant="body1" fontWeight="bold">{t("vouchers.expired")}</Typography>
            <Typography variant="body2">{record?.expired ? t("common.yes") : t("common.no")}</Typography>

            <Typography variant="body1" fontWeight="bold">{t("vouchers.comment")}</Typography>
            <Typography variant="body2">{record?.comment}</Typography>

            <Typography variant="body1" fontWeight="bold">{t("vouchers.consumer")}</Typography>
            <Typography variant="body2">
              {record?.consumer && <Link to={`/organizations/${params.org_id}/consumers/show/${record.consumer.id}`}>{renderFullNameShort(record?.consumer)}</Link>}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={12} sm={9} md={10} item={true}>
          <VoucherShowSide voucher={record}/>
        </Grid>
      </Grid>
    </Show>
  );
};
