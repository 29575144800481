import { useTranslate, HttpError } from "@pankod/refine-core";
import {
  Create,
  Box,
  TextField,
} from "@pankod/refine-mui";
import {useForm} from "@pankod/refine-react-hook-form";

import { IFinanceAccount } from "interfaces";
import React from "react";
import {Typography} from "@mui/material";

export const FinanceAccountCreate: React.FC = () => {
  const t = useTranslate();

  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    formState: { errors },
  } = useForm<IFinanceAccount, HttpError, IFinanceAccount>({
    defaultValues: {
      name: null,
      number: null,
    },
  });

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps} title={<Typography variant="h5">{t("financeAccounts.titles.create")}</Typography>}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("name", {
            required: t("errors.required.field", { field: t("financeAccounts.name") }),
          })}
          error={!!errors.name}
          helperText={errors.name?.message}
          margin="normal"
          fullWidth
          label={t("financeAccounts.name")}
          name="name"
        />
        <TextField
          {...register("currency")}
          error={!!errors.currency}
          helperText={errors.currency?.message}
          margin="normal"
          fullWidth
          label={t("financeAccounts.currency")}
          name="currency"
        />
        <TextField
          {...register("initialBalance", {
            required: t("errors.required.field", {
              field: t("financeAccounts.initialBalance"),
            }),
            pattern: /^[0-9]{0,13}[.,]?[0-9]{0,2}$/
          })}
          margin="normal"
          fullWidth
          label={t("financeAccounts.initialBalance")}
          name="initialBalance"
          inputProps={{ inputMode: 'numeric', pattern: '^[0-9]{0,13}[.,]?[0-9]{0,2}$' }}
          required
          error={!!errors.initialBalance}
          //@ts-ignore
          helperText={errors.initialBalance?.message}
        />
        <TextField
          {...register("number")}
          error={!!errors.number}
          helperText={errors.number?.message}
          margin="normal"
          fullWidth
          label={t("financeAccounts.number")}
          name="number"
        />
      </Box>
    </Create>
  );
};
