import { useTranslate, HttpError } from "@pankod/refine-core";
import {
  Box,
  TextField,
  Autocomplete,
  useAutocomplete,
  MenuItem,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import routerProvider from "@pankod/refine-react-router-v6";
import {IConsumer, IEmployee} from "interfaces";
import React from "react";
import {Typography} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers";
import {localToUTC, makeLocalAppearUTC} from "../../components/fields/dateTimePicker";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {renderFullNameShort} from "../../utils/dataRenderUtils";
import { Create } from "components/crud/create";
import dayjs from "dayjs";
import { IConsumerRouteParams } from ".";
import {createErrorNotification} from "../../utils/errorNotification";

const { useParams } = routerProvider;

export const ConsumerCreate: React.FC = () => {
  const t = useTranslate();
  const params: IConsumerRouteParams = useParams();

  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    control,
    formState: { errors },
  } = useForm<IConsumer, HttpError, IConsumer & { manager: IEmployee }>({
    refineCoreProps: {
      ...createErrorNotification(t, "consumers.titles.create"),
    }
  });

  const { autocompleteProps } = useAutocomplete<IEmployee>({
    resource: `organizations/${params.org_id}/employees`,
    hasPagination: true,
    queryOptions: { enabled: true },
    defaultValueQueryOptions: { enabled: false },
    debounce: 700,
  });

  return (
    <Create
      title={<Typography variant="h5">{t("consumers.titles.create")}</Typography>}
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("lastName")}
          margin="normal"
          fullWidth
          label={t("consumers.lastName")}
          name="lastName"
          autoFocus
        />

        <TextField
          {...register("firstName")}
          margin="normal"
          fullWidth
          label={t("consumers.firstName")}
          name="firstName"
        />

        <TextField
          {...register("middleName")}
          margin="normal"
          fullWidth
          label={t("consumers.middleName")}
          name="middleName"
        />

        <TextField
          {...register("phone", {
            required: t("errors.required.field", { field: t("customers.phone") }),
          })}
          error={!!errors.phone}
          helperText={errors.phone?.message}
          margin="normal"
          fullWidth
          label={t("customers.phone")}
          name="phone"
          required
        />

        <TextField
          {...register("comment")}
          margin="normal"
          fullWidth
          label={t("customers.comment")}
          name="comment"
        />

        <Controller
          control={control}
          name="conversionDateTime"
          // @ts-ignore
          defaultValue={localToUTC(dayjs((new Date()).valueOf()))}
          render={({ field }) => (
            <DateTimePicker
              {...field}
              label={t("consumers.conversionDateTime")}
              value={makeLocalAppearUTC(field.value)}
              onChange={value => field.onChange({ target: { value: localToUTC(value) } })}
              renderInput={(params: any) => <TextField {...params} margin="normal" />}
            />
          )}
        />

        <TextField
          {...register("email")}
          margin="normal"
          helperText={errors.email?.message}
          fullWidth
          label={t("customers.email")}
          name="email"
          type="email"
        />

        <Controller
          control={control}
          name="birthDate"
          render={({ field }) => (
            <DatePicker
              {...field}
              label={t("customers.birthDate")}
              value={makeLocalAppearUTC(field.value)}
              onChange={value => field.onChange({ target: { value: localToUTC(value) } })}
              renderInput={(params: any) => <TextField {...params} margin="normal" />}
            />
          )}
        />

        <Controller
          control={control}
          name="gender"
          defaultValue={""}
          render={({ field }) => (
            <TextField
              {...field}
              margin="normal"
              fullWidth
              label={t("customers.gender")}
              select={true}
              value={field.value ?? ""}
            >
              <MenuItem value="">{t('common.notSelected')}</MenuItem>
              <MenuItem value="male">{t('entity.gender_male')}</MenuItem>
              <MenuItem value="female">{t('entity.gender_female')}</MenuItem>
            </TextField>
          )}
        />

        <TextField
          {...register("city")}
          margin="normal"
          fullWidth
          label={t("customers.city")}
        />

        <Controller
          control={control}
          name="status"
          rules={{
            required: t("errors.required.field", { field: t("customers.status") }),
          }}
          defaultValue={1}
          render={({ field }) => (
            <TextField
              {...field}
              margin="normal"
              fullWidth
              label={t("customers.status")}
              select={true}
              required
              error={!!errors.status}
              helperText={errors.status?.message}
            >
              <MenuItem value={1}>{t('consumers.status_1')}</MenuItem>
              <MenuItem value={0}>{t('consumers.status_0')}</MenuItem>
            </TextField>
          )}
        />

        <Controller
          control={control}
          name="manager"
          defaultValue={null as any}
          render={({ field }) => (
            <Autocomplete
              {...autocompleteProps}
              {...field}
              clearOnEscape
              noOptionsText={t('common.searchNoItems')}
              onChange={(_, value) => {
                field.onChange(value?.id || value);
              }}
              getOptionLabel={(item) => {
                return renderFullNameShort((item.lastName || item.firstName)
                  ? item
                  : autocompleteProps?.options?.find(
                    (p) =>
                      p.id.toString() ===
                      item.toString(),
                  )) ?? "";
              }}
              isOptionEqualToValue={(option, value: IEmployee|number) =>
                value === undefined || option.id.toString() === value.toString() || (typeof value === 'object' && option.id === value?.id)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("customers.manager")}
                  margin="normal"
                  variant="outlined"
                  error={!!errors.manager}
                  helperText={errors.manager?.message}
                />
              )}
            />
          )}
        />
      </Box>
    </Create>
  );
};
