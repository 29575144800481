import routerProvider from "@pankod/refine-react-router-v6";
import React from "react";
import {useGetLocale, useRouterContext, useTranslate} from "@pankod/refine-core";
import {
  Box,
  Button,
  ButtonGroup,
  DataGrid,
  DeleteButton,
  EditButton,
  GridColumns, GridFooter, GridFooterContainer,
  ruRU,
  ShowButton,
  Stack,
  useDataGrid
} from "@pankod/refine-mui";
import {IFinanceAccount} from "../../interfaces";
import {List} from "../../components/crud/list";
import {IFinanceAccountRouteParams} from "./index";
import {SimplePopper} from "../../components/simplePopper";
import {renderCurrency} from "../../utils/dataRenderUtils";

const { useParams } = routerProvider;

export const FinanceAccountList: React.FC = () => {
  let total: Record<string, number> = {};
  const t = useTranslate();
  const { Link } = useRouterContext();
  const locale = useGetLocale();

  const params: IFinanceAccountRouteParams = useParams();
  const {dataGridProps} = useDataGrid<IFinanceAccount>({
    resource: `organizations/${params.org_id ?? 0}/accounts`,
  });

  if (!dataGridProps.loading) {
    dataGridProps.rows.forEach((v) => {
      total[String(v.currency)] = (total[String(v.currency)] ?? 0.0) + Number(v.overallBalance);
    });
  }

  const columns = React.useMemo<GridColumns<IFinanceAccount>>(
    () => [
      {
        field: "id",
        headerName: t("entity.id"),
        type: "number",
        width: 50,
      },
      {
        field: "name",
        headerName: t("financeAccounts.name"),
        minWidth: 100,
        flex: 1,
        hideable: false,
        filterable: false,
      },
      {
        field: "overallBalance",
        headerName: t("financeAccounts.overallBalance"),
        minWidth: 100,
        flex: 1,
        hideable: false,
        sortable: false,
        filterable: false,
        valueGetter: params => renderCurrency(params.row.overallBalance, locale(), params.row.currency),
      },
      {
        field: "tactions",
        type: "actions",
        headerName: t("table.actions"),
        renderCell: function render({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <SimplePopper toggleName={t("common.more")}>
                <ButtonGroup size="small" aria-label="small button group" orientation="vertical">
                  <Button component={Link} to={`${row.id}/operations`} size="small" variant="contained">{t("layout.menuHrefFinanceOperations")}</Button>
                </ButtonGroup>
              </SimplePopper>
              <ShowButton size="small" hideText recordItemId={row.id} />
              <EditButton size="small" hideText recordItemId={row.id} resourceNameOrRouteName={`organizations/${params.org_id}/accounts`} />
            </Stack>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 200,
      },
    ],
    [t]
  );

  return (
    <List
      title={t("financeAccounts.titles.list")}
      canCreate={true}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 60}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Footer: CustomFooterTotalComponent,
        }}
        componentsProps={{
          footer: {
            total: total,
          },
        }}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
        }}
      />
    </List>
  );
}

const CustomFooterTotalComponent = ({total}: { total: Record<string, number> }): JSX.Element => {
  const t = useTranslate();
  const locale = useGetLocale();

  const totalRender = Object.keys(total).length
    ? Object.keys(total).reduce((accumulator: string, k: string) => accumulator + renderCurrency(total[k], locale(), k) + ' ', '')
    : 0;

  return (
    <>
      <GridFooterContainer><Box sx={{ padding: "10px", display: "flex" }}>{t('financeAccounts.totalSum', {sum: totalRender})}</Box></GridFooterContainer>
      <GridFooter />
    </>
  );
}

