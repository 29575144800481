import {useTranslate, HttpError, useCustom, useApiUrl, BaseRecord} from "@pankod/refine-core";
import {
  Box,
  TextField, FormControlLabel, Switch, Autocomplete,
} from "@pankod/refine-mui";
import {Controller, useForm} from "@pankod/refine-react-hook-form";

import {IEmployee} from "interfaces";
import React from "react";
import {Typography} from "@mui/material";
import {createErrorNotification} from "../../utils/errorNotification";
import routerProvider from "@pankod/refine-react-router-v6";
import {IEmployeeRouteParams} from "./index";
import {Edit} from "../../components/crud/edit";

const { useParams } = routerProvider;

export const EmployeeEdit: React.FC = () => {
  const t = useTranslate();
  const params: IEmployeeRouteParams = useParams();

  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    control,
    formState: { errors },
  } = useForm<IEmployee, HttpError, IEmployee>({
    defaultValues: {active: false},
    refineCoreProps: {
      resource: `organizations/${params.org_id}/employees`,
      ...createErrorNotification(t, "employees.titles.create"),
    },
  });

  const apiUrl = useApiUrl();

  const { data: permissionsOptionsResponse, isLoading } = useCustom({
    url: `${apiUrl}/organizations/${params.org_id}/employees/permissions`,
    method: "get",
  });

  const permissionsOptions = permissionsOptionsResponse?.data.map((perm: BaseRecord) => perm) ?? [];

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title={<Typography variant="h5">{t("employees.titles.edit")}</Typography>}
      canDelete={true}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("email")}
          error={!!errors.email}
          helperText={errors.email?.message}
          margin="normal"
          fullWidth
          label={t("employees.email")}
          name="email"
          autoFocus
        />
        <TextField
          {...register("lastName")}
          error={!!errors.lastName}
          helperText={errors.lastName?.message}
          margin="normal"
          fullWidth
          label={t("employees.lastName")}
          name="lastName"
        />
        <TextField
          {...register("firstName", {
            required: t("errors.required.field", { field: t("employees.firstName") }),
          })}
          error={!!errors.firstName}
          helperText={errors.firstName?.message}
          margin="normal"
          fullWidth
          label={t("employees.firstName")}
          name="firstName"
        />
        <TextField
          {...register("middleName")}
          error={!!errors.middleName}
          helperText={errors.middleName?.message}
          margin="normal"
          fullWidth
          label={t("employees.middleName")}
          name="middleName"
        />
        <Controller
          control={control}
          name="active"
          render={({ field }) => (
            <FormControlLabel control={
              <Switch checked={field.value} onChange={field.onChange} />
            } label={t("employees.active")} />
          )}
        />
        <Controller
          control={control}
          name="permissions"
          defaultValue={[]}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={permissionsOptions}
              clearOnEscape
              disableCloseOnSelect
              multiple
              noOptionsText={t('common.searchNoItems')}
              onChange={(_, value) => {
                field.onChange(value.map((i) => i) || value);
              }}
              getOptionLabel={(option) => t(`employees.${option}`)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("employees.permissions")}
                  margin="normal"
                  variant="outlined"
                  error={!!errors.permissions}
                  //@ts-ignore
                  helperText={errors.permissions?.message}
                />
              )}
            />
          )}
        />

      </Box>
    </Edit>
  );
};
