import React, {createContext, PropsWithChildren, useEffect, useState} from "react";
import {IOrganization} from "../interfaces";
import routerProvider from "@pankod/refine-react-router-v6";
import {HttpError, useList} from "@pankod/refine-core";

type OrganizationContextType = {
  organization: IOrganization|null;
  setOrganization: (organization: IOrganization|number|null) => void;
};

export const OrganizationContext = createContext<OrganizationContextType>(
  {} as OrganizationContextType
);

export const OrganizationContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const organizationFromLocalStorage = localStorage.getItem("organization");

  const [organization, setOrganization] = useState(
    organizationFromLocalStorage ? JSON.parse(organizationFromLocalStorage) : null
  );

  const { useParams } = routerProvider;
  const params: { org_id?: string } = useParams();
  const { data, isLoading, isError } = useList<IOrganization, HttpError>({
    resource: "organizations",
  });

  const organizations = data?.data ?? [];
  if (isLoading) {
  }
  if (isError) {
  }

  useEffect(() => {
    if (Number.isInteger(organization)) {
      setOrganization(organizations.find(org => org.id === organization));
      return;
    }
    if (organization) {
      window.localStorage.setItem("organization", JSON.stringify(organization));
    } else {
      window.localStorage.removeItem("organization");
    }
  }, [organization]);

  useEffect(() => {
    if (params.org_id) {
      const newOrganization = organizations.find(org => org.id.toString() === params.org_id);
      if (newOrganization) {
        setOrganization(newOrganization);
      }
    }
  }, [params]);

  return (
    <OrganizationContext.Provider
      value={{
        setOrganization: (organization: IOrganization|number|null) => {
          setOrganization(organization);
        },
        organization,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};
