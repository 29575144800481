import {Menu, MenuItem, PopoverProps} from '@pankod/refine-mui';
import * as React from 'react';

interface IBasicMenuProps {
  handleClose: () => void;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  anchorEl: PopoverProps['anchorEl'];
  items: Array<{
    icon?: React.ReactNode,
    text: string,
    onClick: Function,
  }>
  children: React.ReactElement;
  id: string;
}

export const BasicMenu = ({items, handleClick, handleClose, anchorEl, children, id}: IBasicMenuProps): JSX.Element => {

  const open = Boolean(anchorEl);
  const menuId = id + "-menu";
  const buttonId = id + "-button";

  return (
    <React.Fragment>
      {React.cloneElement(
        children,
        { id: buttonId,
          "aria-controls": open ? menuId : undefined,
          "aria-haspopup": "true",
          "aria-expanded": open ? 'true' : undefined,
          onClick: handleClick
        }
      )}
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': buttonId,
        }}
      >
        {items.map((item, index) => (
          <MenuItem key={index} onClick={() => {handleClose(); item.onClick()}}>{item.text}</MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}

export function useBasicMenuState() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return {handleClick: handleClick, handleClose: handleClose, anchorEl: anchorEl};
}