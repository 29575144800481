import * as React from 'react';
import Popper from '@mui/material/Popper';
import {Button} from "@pankod/refine-mui";

interface SimplePopperProps {
  children: React.ReactNode;
  toggleName: string;
}

export function SimplePopper({children, toggleName}: SimplePopperProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <div>
      <Button aria-describedby={id} type="button" onClick={handleClick}>
        {toggleName}
      </Button>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        {children}
      </Popper>
    </div>
  );
}