import {useTranslate, HttpError} from "@pankod/refine-core";
import {
  Box,
  TextField
} from "@pankod/refine-mui";
import {useForm} from "@pankod/refine-react-hook-form";

import {IFinanceAccount} from "interfaces";
import React from "react";
import {Typography} from "@mui/material";
import {createErrorNotification} from "../../utils/errorNotification";
import {Edit} from "../../components/crud/edit";
import {useParams} from "react-router-dom";

export const FinanceAccountEdit: React.FC = () => {
  const t = useTranslate();
  const { id } = useParams();

  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    formState: { errors },
  } = useForm<IFinanceAccount, HttpError, IFinanceAccount>({
    refineCoreProps: {
      resource: 'accounts',
      id: id,
      ...createErrorNotification(t, "financeAccounts.titles.create"),
    },
  });

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title={<Typography variant="h5">{t("financeAccounts.titles.edit")}</Typography>}
      canDelete={true}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("name", {
            required: t("errors.required.field", { field: t("financeAccounts.name") }),
          })}
          error={!!errors.name}
          helperText={errors.name?.message}
          margin="normal"
          fullWidth
          label={t("financeAccounts.name")}
          name="name"
        />
        <TextField
          {...register("currency")}
          error={!!errors.currency}
          helperText={errors.currency?.message}
          margin="normal"
          fullWidth
          label={t("financeAccounts.currency")}
          name="currency"
        />
        <TextField
          {...register("initialBalance", {
            required: t("errors.required.field", {
              field: t("financeAccounts.initialBalance"),
            }),
            pattern: {
              value: /^[0-9]{0,13}[.,]?[0-9]{0,2}$/,
              message: t("errors.pattern.decimal"),
            },
          })}
          margin="normal"
          fullWidth
          label={t("financeAccounts.initialBalance")}
          name="initialBalance"
          inputProps={{ inputMode: 'numeric', pattern: '^[0-9]{0,13}[.,]?[0-9]{0,2}$' }}
          required
          error={!!errors.initialBalance}
          //@ts-ignore
          helperText={errors.initialBalance?.message}
        />
        <TextField
          {...register("number")}
          error={!!errors.number}
          helperText={errors.number?.message}
          margin="normal"
          fullWidth
          label={t("financeAccounts.number")}
          name="number"
        />
      </Box>
    </Edit>
  );
};
