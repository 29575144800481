import React, {useEffect} from "react";
import dayjs from "dayjs";
import {Button, ButtonGroup, Grid, MenuItem, RefreshButton, Stack, TextField} from "@pankod/refine-mui";
import {useApiUrl, useCustom, useTranslate} from "@pankod/refine-core";
import {FinanceReportOperationsChart} from "./financeReportOperationsChart";
import {FinanceReportOperationsMonthlyBars} from "./financeReportOperationsMonthlyBars";
import {IConsumerRouteParams} from "../consumers";
import routerProvider from "@pankod/refine-react-router-v6";
import {IReportFinanceOperations} from "./index";
import {DatePicker} from "@mui/x-date-pickers";
import {ArrowBackIos, ArrowForwardIos} from "@mui/icons-material";
import {FinanceReportOperationsMonthlyLine} from "./financeReportOperationsMonthlyLine";

const { useParams } = routerProvider;

export const FinanceOperationsReport = (): JSX.Element => {
  const t = useTranslate();
  const urlParams: IConsumerRouteParams = useParams();
  const apiUrl = useApiUrl();
  const [dateFromFilter, setDateFromFilter] = React.useState(dayjs().format('YYYY-MM-01'));
  const [dateToFilter, setDateToFilter] = React.useState(dayjs().endOf('month').format('YYYY-MM-DD'));
  const [groupBy, setGroupBy] = React.useState('category');

  const { data, isError, error, refetch } = useCustom<Array<IReportFinanceOperations>>({
    url: `${apiUrl}/organizations/${urlParams.org_id}/reports/finance-operations`,
    method: "get",
    config: {
      query: {
        groupBy: groupBy,
        dateFrom: dateFromFilter,
        dateTo: dateToFilter,
      },
    },
  });

  const { data: dataMonthly, isError: isErrorMonthly, error: errorMonthly, refetch: refetchMonthly } = useCustom<Array<IReportFinanceOperations>>({
    url: `${apiUrl}/organizations/${urlParams.org_id}/reports/finance-operations`,
    method: "get",
    config: {
      query: {
        groupBy: 'month',
        dateFrom: dateFromFilter,
        dateTo: dateToFilter,
      },
    },
  });

  useEffect(() => {
    refetch();
    refetchMonthly();
  }, [dateFromFilter, dateToFilter, groupBy]);

  const setYearlyFilters = () => {
    setDateFromFilter(dayjs().startOf('year').format('YYYY-MM-DD'));
    setDateToFilter(dayjs().endOf('year').format('YYYY-MM-DD'));
  };

  const setPrevMonthFilters = () => {
    setDateFromFilter(dayjs(dateFromFilter).subtract(1, 'month').format('YYYY-MM-DD'));
    setDateToFilter(dayjs(dateToFilter).subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
  };

  const setNextMonthFilters = () => {
    setDateFromFilter(dayjs(dateFromFilter).add(1, 'month').format('YYYY-MM-DD'));
    setDateToFilter(dayjs(dateToFilter).add(1, 'month').endOf('month').format('YYYY-MM-DD'));
  };

  if (isError || isErrorMonthly) {
    console.error("Error fetching operations chart data", error);
    return <></>;
  }

  if (!data?.data) {
    return <></>;
  }

  const expenses = data.data.filter(item => item.type === 'Expense');
  const incomes = data.data.filter(item => item.type === 'Income');

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={4} md={3} lg={2}>
          <DatePicker
            views={['day', 'month', 'year']}
            label={t("reports.financeOperations.dateFromFilter")}
            value={dateFromFilter}
            onChange={value => setDateFromFilter(dayjs(value).format('YYYY-MM-DD'))}
            renderInput={(params: any) => <TextField {...params} margin="normal" size="small" variant="standard"/>}
          />
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <DatePicker
            views={['day', 'month', 'year']}
            label={t("reports.financeOperations.dateToFilter")}
            value={dateToFilter}
            onChange={value => setDateToFilter(dayjs(value).format('YYYY-MM-DD'))}
            renderInput={(params: any) => <TextField {...params} margin="normal" size="small" variant="standard"/>}
          />
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <TextField
            margin="normal"
            label={t("reports.financeOperations.groupBy")}
            select={true}
            value={groupBy}
            size="small"
            fullWidth
            onChange={event => {setGroupBy(event.target.value);}}
          >
            <MenuItem value={'category'}>{t('reports.financeOperations.groupBy_category')}</MenuItem>
            <MenuItem value={'payee'}>{t('reports.financeOperations.groupBy_payee')}</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={10} lg={6}>
          <Stack direction="row" spacing={1} alignItems="stretch">
            <ButtonGroup size="medium" variant="outlined" aria-label="Quick filters">
              <RefreshButton size="small" variant="outlined" onClick={() => {refetch(); refetchMonthly();}}></RefreshButton>
            </ButtonGroup>
            <ButtonGroup size="medium" variant="outlined" aria-label="Quick filters">
              <Button onClick={setYearlyFilters}>{t("reports.financeOperations.yearlyFilter")}</Button>
              <Button onClick={setPrevMonthFilters} startIcon={<ArrowBackIos />}>{t("reports.financeOperations.prevMonth")}</Button>
              <Button onClick={setNextMonthFilters} endIcon={<ArrowForwardIos />}>{t("reports.financeOperations.nextMonth")}</Button>
            </ButtonGroup>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12} md={12} lg={6} item={true} key="incomes">
          <FinanceReportOperationsChart title={t("reports.financeOperations.Incomes")} data={incomes}/>
        </Grid>
        <Grid xs={12} md={12} lg={6} item={true} key="expenses">
          <FinanceReportOperationsChart title={t("reports.financeOperations.Expenses")} data={expenses}/>
        </Grid>
      </Grid>
      <Grid container spacing={3} marginTop={1}>
        <Grid xs={12} item={true}>
          <FinanceReportOperationsMonthlyBars data={dataMonthly?.data} setDateFromFilter={setDateFromFilter} setDateToFilter={setDateToFilter}/>
        </Grid>
      </Grid>
      <Grid container spacing={3} marginTop={1}>
        <Grid xs={12} item={true}>
          <FinanceReportOperationsMonthlyLine data={dataMonthly?.data}/>
        </Grid>
      </Grid>
    </>
  );
};