import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(LocalizedFormat);
const defaultLocale = dayjs.locale();

export interface INameAware {
    firstName: string|null;
    lastName: string|null;
    middleName: string|null;
    name: string|null;
}

export interface IFullNameAware {
    firstName: string|null;
    lastName: string|null;
    middleName: string|null;
}

export function renderName(item?: INameAware|null) {
    if (!item) {
        return '';
    }
    if (item.lastName || item.firstName) {
        return renderFullNameLong(item);
    } else {
        return item.name;
    }
}

export function renderFullNameShort(item?: IFullNameAware|null) {
    if (!item) {
        return '';
    }
    let result = '';
    result += item.lastName ? item.lastName : (item.firstName ?? '');
    if (item.lastName && item.firstName) {
        result += (' ' + item.firstName[0] + '.');
        result += item.middleName ? (item.middleName[0] + '.') : '';
    }
    return result;
}

export function renderFullNameLong(item?: IFullNameAware|null) {
    if (!item) {
        return '';
    }
    let result = '';
    result += item.lastName ? item.lastName : '';
    if (item.firstName) {
        result += (' ' + item.firstName);
        result += item.middleName ? (' ' + item.middleName) : '';
    }
    return result;
}

export function renderInitials(item?: IFullNameAware|null) {
    if (!item) {
        return '';
    }
    let result = '';
    result += item.lastName ? item.lastName[0] : '';
    result += item.firstName ? item.firstName[0] : '';
    return result;
}

export function normalizeDateTime(value: Date) {
    return value.getFullYear() + '-'
      + (value.getMonth() + 1) + '-'
      + value.getDate() + ' '
      + value.getHours() + ':'
      + value.getMinutes();
}

export function renderDateTime(value: number|string|Date, locale?: string, dateFormat = "L") {
    if (!value) {
        return '';
    }
    return dayjs(value)
      .locale(locale || defaultLocale)
      .format(dateFormat);
}

export function renderCurrency(amount: number, locale?: string, currency: string | null = null, type: string | null = null): string {
    const value = (type && type !== 'Income') ? amount * -1.00 : amount;
    let options;
    if (currency) {
        options = {
            style: 'currency',
            currency: currency,
        };
    } else {
        options = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }
    }
    return new Intl.NumberFormat(locale, options).format(value);
}