import {Controller, UseModalFormReturnType} from "@pankod/refine-react-hook-form";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControlLabel,
  LinearProgress,
  Switch,
  TextField, useAutocomplete,
  // useMediaQuery,
  // useTheme
} from "@pankod/refine-mui";
import {localToUTC, makeLocalAppearUTC} from "../../components/fields/dateTimePicker";
import {DateTimePicker} from "@mui/x-date-pickers";
import React from "react";
import {useTranslate} from "@pankod/refine-core";
import {IConsumerRouteParams} from "../consumers";
import routerProvider from "@pankod/refine-react-router-v6";
import {IEmployee, IServiceType} from "../../interfaces";
import {renderFullNameShort} from "../../utils/dataRenderUtils";

const { useParams } = routerProvider;

export const ServiceItemEditModal: React.FC<UseModalFormReturnType> = ({
  register,
  control,
  formState: { errors },
  refineCore: {  formLoading },
  // handleSubmit,
  modal: { visible, close },
  saveButtonProps,
}) => {
  const t = useTranslate();
  const params: IConsumerRouteParams = useParams();
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { autocompleteProps: managerAutocompleteProps } = useAutocomplete<IEmployee>({
    resource: `organizations/${params.org_id}/employees`,
    hasPagination: true,
    queryOptions: { enabled: true },
    defaultValueQueryOptions: { enabled: false },
    debounce: 700,
  });

  const { autocompleteProps: serviceTypeAutocompleteProps } = useAutocomplete<IServiceType>({
    resource: `organizations/${params.org_id}/services`,
    hasPagination: true,
    queryOptions: { enabled: true },
    defaultValueQueryOptions: { enabled: false },
    debounce: 700,
  });

  return (
    <Dialog
      open={visible}
      onClose={close}
      maxWidth="xs"
      // fullScreen={fullScreen}
    >
      {formLoading && <LinearProgress />}
      <DialogTitle>{t("customers.actions.titles.edit")}</DialogTitle>
      <DialogContent>

        <Controller
          control={control}
          name="serviceType"
          rules={{
            required: t("errors.required.field", { field: t("dealServices.serviceType") }),
          }}
          defaultValue={null as any}
          render={({ field }) => (
            <Autocomplete
              {...serviceTypeAutocompleteProps}
              {...field}
              clearOnEscape
              noOptionsText={t('common.searchNoItems')}
              onChange={(_, value) => {
                field.onChange(value?.id || value);
              }}
              getOptionLabel={(item) => {
                return item.title ? `${item.title} (${item.cost}${item.currency})` : serviceTypeAutocompleteProps?.options?.find(
                  (p) => p.id.toString() === item.toString(),
                )?.title ?? "";
              }}
              isOptionEqualToValue={(option, value: IServiceType|number) =>
                value === undefined || option.id.toString() === value.toString() || (typeof value === 'object' && option.id === value?.id)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("dealServices.serviceType")}
                  margin="normal"
                  variant="outlined"
                  required
                  error={!!errors.serviceType}
                  //@ts-ignore
                  helperText={errors.serviceType?.message}
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name="executionDateTime"
          rules={{
            required: t("errors.required.field", { field: t("dealServices.executionDateTime") }),
          }}
          render={({ field }) => (
            <DateTimePicker
              {...field}
              label={t("dealServices.executionDateTime")}
              value={makeLocalAppearUTC(field.value)}
              onChange={value => field.onChange({ target: { value: localToUTC(value) } })}
              renderInput={(params: any) => <TextField
                {...params}
                error={!!errors.executionDateTime}
                helperText={errors.executionDateTime?.message}
                required
                margin="normal"
              />}
            />
          )}
        />

        <TextField
          {...register("comment")}
          margin="normal"
          fullWidth
          label={t("dealServices.comment")}
          multiline={true}
          maxRows={5}
          name="comment"
        />

        <Controller
          control={control}
          name="executors"
          defaultValue={[]}
          render={({ field }) => (
            <Autocomplete
              {...managerAutocompleteProps}
              {...field}
              clearOnEscape
              disableCloseOnSelect
              multiple
              noOptionsText={t('common.searchNoItems')}
              onChange={(_, value) => {
                field.onChange(value.map((i) => i.id ?? i) || value);
              }}
              getOptionLabel={(item) => {
                return item ? renderFullNameShort(item.lastName
                  ? item
                  : managerAutocompleteProps?.options?.find(
                    (p) =>
                      p.id.toString() ===
                      item.toString(),
                  )) : "";
              }}
              isOptionEqualToValue={(option, value: IEmployee|number) => {
                return value === undefined || option.id.toString() === value.toString() || (typeof value === 'object' && option.id === value?.id);
              }

              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("dealServices.executors")}
                  margin="normal"
                  variant="outlined"
                  error={!!errors.executors}
                  //@ts-ignore
                  helperText={errors.executors?.message}
                />
              )}
            />
          )}
        />

        <TextField
          {...register("amount", {
            required: t("errors.required.field", {
              field: t("dealServices.amount"),
            }),
            pattern: /^[0-9]{0,13}[.,]?[0-9]{0,2}$/,
          })}
          margin="normal"
          fullWidth
          label={t("dealServices.amount")}
          name="amount"
          inputProps={{ inputMode: 'numeric', pattern: '^[0-9]{0,13}[.,]?[0-9]{0,2}4' }}
          required
          error={!!errors.amount}
          //@ts-ignore
          helperText={errors.amount?.message || t("dealServices.amountHelperText")}
        />

        <TextField
          {...register("customTotalPrice", {
            required: t("errors.required.field", {
              field: t("dealServices.customTotalPrice"),
            }),
            pattern: /^[0-9]{0,13}[.,]?[0-9]{0,2}$/
          })}
          margin="normal"
          fullWidth
          label={t("dealServices.customTotalPrice")}
          name="customTotalPrice"
          inputProps={{ inputMode: 'numeric', pattern: '^[0-9]{0,13}[.,]?[0-9]{0,2}$' }}
          required
          error={!!errors.customTotalPrice}
          //@ts-ignore
          helperText={errors.customTotalPrice?.message}
        />

        <Controller
          control={control}
          name="executed"
          render={({ field }) => (
            <FormControlLabel control={
              <Switch checked={field.value} onChange={field.onChange} />
            } label={t("dealServices.executed")} />
          )}
        />

        <TextField
          {...register("executionPlace")}
          margin="normal"
          fullWidth
          label={t("dealServices.executionPlace")}
          name="executionPlace"
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={() => close()}>{t("buttons.cancel")}</Button>
        <Button {...saveButtonProps}>{t("common.save")}</Button>
      </DialogActions>
    </Dialog>
  );
};