import { CrudSorting } from "@pankod/refine-core";

export const generateSort = (sorts?: CrudSorting) => {
  if (sorts && sorts.length > 0) {
    const _sort: string[] = [];
    const _order: string[] = [];
    const sort: object = {};

    sorts.map((item) => {
      _sort.push(item.field);
      _order.push(item.order);
      Object.assign(sort, {[`sort[${item.field}]`]: item.order});
    });

    return {
      _sort,
      _order,
      sort,
    };
  }

  return;
};
